* {
  margin: 0px;
  padding: 0px;
  outline: none !important;
}

body.html {
  overflow-x: hidden;
}

@font-face {
  src: url(../fonts/Bilbo-Regular.ttf);
  font-family: bilbo;

}

body {
  font-family: 'Anek Malayalam', sans-serif !important;
  padding: 0px !important;
  width: 100%;
  overflow-x: hidden !important;
}

body.light {
  background: #f1f1f1 !important;
}

body.light .text-white {
  color: rgba(0, 0, 0, 0.8) !important;
}

body.dark {
  background: #2a2c42 !important;
}

body::-webkit-scrollbar {
  height: 0px !important;
}

a {
  text-decoration: none !important;
}

.cursor {
  cursor: pointer;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

:root {
  scroll-behavior: auto !important;
}

.row {
  width: 100%;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
  line-height: 20px;
}

.font-14 {
  font-size: 14px;
  line-height: 25px;
}

.font-15 {
  font-size: 15px;
  line-height: 26px;
}

.font-16 {
  font-size: 16px !important;
  line-height: 28px !important;
}

.font-18 {
  font-size: 18px !important;
  line-height: 30px !important;
}

.font-20 {
  font-size: 20px !important;
  line-height: 28px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-w-300 {
  font-weight: 300 !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-700 {
  font-weight: 700 !important;
}



.top-padding {
  margin-top: 110px;
}

.w-100 {
  width: 100%;
  max-width: 100% !important;
  flex: 0 0 100%;
}

.transition {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
}

@-webkit-keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

button.bg-red,
button.btn-border {
  transform: perspective(1px) translateZ(0);
  border-radius: 7px !important;
}

/* button.bg-red:hover, button.bg-red:focus, button.bg-red:active, button.btn-border:hover, button.btn-border:focus, button.btn-border:active {
  -webkit-animation-name: hvr-pulse-shrink;
  animation-name: hvr-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
} */


.bg-red {
  background: #47b9d5 !important;
}

button.bg-red.btn-round {
  border-radius: 30px !important;
}

.btn-gray {
  background: #595058 !important;
}

.btn-gray:hover {
  background: #4daed9 !important;
}

.text-red {
  color: #47b9d5;
  font-weight: 500 !important;
}

.btn-lg {
  font-size: 20px !important;
  padding: 8px 30px !important;
}

.btn-border {
  border: 1px solid #47b9d5 !important;
  color: #47b9d5 !important;
  font-weight: 600 !important;
}

.btn-border:hover {
  background: #30869b !important;
  color: #fff !important;
}

.font-w-300 {
  font-weight: 300 !important;
}

.txt-light {
  color: #000 !important;
}

.txt-light2 {
  color: #000 !important;
}


.txt-light2 {
  color: #000 !important;
}

body.dark .txt-light,
body.dark .txt-light2 {
  color: #fff !important;
}

.txt-dull {
  opacity: 0.7;
}

.ml-auto {
  margin-left: auto;
}

.caution {
  color: #F44336;
}


button.bg-red {
  border: 1px solid transparent !important;
  background-image: linear-gradient(to right, #47b9d5, #39a1bb) !important;
  color: #fff !important;
  border-radius: 7px !important;
}

button.bg-red::before {
  display: none;
}

button {
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

button.bg-red:hover {
  border: 1px solid #39a1bb !important;
  background-image: linear-gradient(to right, #39a1bb, #47b9d5) !important;
}


::-webkit-scrollbar {
  width: 10px !important;
  cursor: pointer;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-overflow-scrolling: touch;
}

.height-box:hover::-webkit-scrollbar {
  width: 14px;
}


/* Track */

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: transparent !important;

}


/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #47b9d5;
  cursor: pointer;

}


::selection {
  color: #fff;
  background: #47b9d5;
}

.infinite-scroll-component {
  overflow: inherit !important;
  padding-bottom: 0px;
}

.border-effect {
  position: relative;
  color: #47b9d5;
  text-decoration: none;
  font-weight: 500;
}

.border-effect:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  content: "";
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  background: #47b9d5;
}

.border-effect:hover {
  text-decoration: none;
  color: #47b9d5;
}

.border-effect:hover:after {
  width: 100%;
}


.ipadMenu {
  display: none;
}


header {
  width: 100%;
  height: 150px;
  position: fixed;
  z-index: 1000000 !important;
  top: 0px;
  left: 0px;
  z-index: 100;
  transition: all 0.2s;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.0));
  padding: 13px 0px;
  align-items: flex-start;
}

body.light header.light {
  background: #fff !important;
}

body.dark header.light {
  background: #12152c !important;
}

header.light {
  background-image: none !important;
  height: auto !important;
  padding: 10px 0px !important;
}

body.dark .header2 {
  background: none;
}

header.light .header_Tabs li a {
  color: #000 !important;
}

body.dark header.light .header_Tabs li a {
  color: #fff !important;
}

header.light .header_Tabs li::after {
  background: #000 !important;
}

header .logo {
  width: 60px;
}

body.light header.sticky {
  background: #fff;
}

body.dark header.sticky {
  background: #182738;
}

header.sticky {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100000;
  height: 80px;
  padding: 0px 0px;
  align-items: center;
  box-shadow: 0px 1px 5px #0000001a;
}

header .item2 {
  display: flex;
}

header .header_Tabs li {
  margin: 0px !important;
  padding: 0px 15px;
  position: relative;
}

body.light header .header_Tabs li::after {
  background: #000;
}

body.dark header .header_Tabs li::after {
  background: #fff;
}

header .header_Tabs li::after {
  content: '';
  width: 1px;
  height: 13px;
  position: absolute;
  top: 7px;
  right: 0px;
  opacity: 0.5;
}

body.light header.sticky .header_Tabs li::after {
  background: #000 !important;
}

body.dark header.sticky .header_Tabs li::after {
  background: #fff !important;
}

header .header_Tabs li:last-child::after {
  display: none;
}

body.light header .header_Tabs li a {
  color: #fff;
  font-weight: 500;
}

body.dark header .header_Tabs li a {
  color: #fff;
  font-weight: 400;
}

header .header_Tabs li a {
  font-size: 17px;
  opacity: 0.8;
  position: relative;
  transition: all 0.3s;
}

header .header_Tabs li a::before {
  content: '';
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  z-index: 10;
  background: #47b9d5;
  transition: all 0.3s;
  opacity: 0;
}

header .header_Tabs li a:hover::before,
header .header_Tabs li a.active::before {
  opacity: 1;
  left: 0px;
  width: 100%;
}
header .header_Tabs li a:hover::before{
color: #47b9d5;
}
header .header_Tabs li a:hover::before,
header .header_Tabs li a.active {
  opacity: 1 !important;
}

body.light header.sticky .header_Tabs li a {
  color: rgba(0, 0, 0, 0.8) !important;
}

body.dark header.sticky .header_Tabs li a {
  color: #fff !important;
}

header.sticky .header_Tabs li a {
  opacity: 1 !important;
}


header button {
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 5px 25px !important;
  height: 45px;
}

header .btn-white {
  border: 1px solid rgba(255, 255, 255, 0.0) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

header .btn-white:hover {
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

header .header_Tabs li a:hover {
  opacity: 1;
}



body.light .headerSearch {
  border: 1px solid rgba(255, 255, 255, 0.6);
}

body.dark .headerSearch {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.headerSearch {
  width: 60%;
  height: 60px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s;
  left: 10px;
  margin: auto;
  margin-bottom: 30px;
}

body.light header.sticky .headerSearch {
  border: 1px solid rgba(0, 0, 0, 0.7);
}

body.light header.light .headerSearch {
  border: 1px solid rgba(0, 0, 0, 0.4);
}


body.dark header.sticky .headerSearch {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

body.dark header.light .headerSearch {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

body.light .headerSearch svg {
  color: rgba(255, 255, 255, 0.5) !important;
}

body.dark .headerSearch svg {
  color: rgba(255, 255, 255, 0.8) !important;
}


.headerSearch svg {
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 0.3s;
  z-index: 1000;
  cursor: pointer;
  font-size: 30px;
}

body.light header.sticky .headerSearch svg {
  color: #000 !important;
  opacity: 0.7;
}


body.dark header.sticky .headerSearch svg {
  color: #fff !important;
}

header.light .headerSearch svg {
  color: #000 !important;
  opacity: 0.7;
}

body.dark header.light .headerSearch svg {
  color: #fff !important;
}

.headerSearch input {
  color: rgba(255, 255, 255, 0.8);
  background: none !important;
  border: 0px !important;
  padding-left: 20px;
  padding-right: 40px;
  height: 58px;
  width: 100%;
  font-size: 17px;
}

header.light .headerSearch input,
header.sticky .headerSearch input {
  color: rgba(0, 0, 0, 0.8);
}

body.dark header.light .headerSearch input,
body.dark header.sticky .headerSearch input {
  color: #fff !important;
}



.headerSearch input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
  font-weight: 300;
}


.headerSearch.focus input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  font-weight: 300;
}



header.sticky .headerSearch input::-webkit-input-placeholder {
  color: #000 !important;
  font-size: 17px;
  font-weight: 500;
}




header.sticky .headerSearch.focus input::-webkit-input-placeholder {
  color: #000 !important;
  font-size: 17px;
  font-weight: 500;
}

body.dark .headerSearch input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark header.light .headerSearch input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark header.sticky .headerSearch input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

header.light .headerSearch input::-webkit-input-placeholder {
  color: #616161 !important;
  font-size: 14px;
  font-weight: 500;
}

header.light .headerSearch.focus input::-webkit-input-placeholder {
  color: #616161 !important;
  font-size: 14px;
  font-weight: 500;
}



.headerSearch.focus {
  border: 1px solid rgba(255, 255, 255, 0.6);
  width: 350px;
}

.headerSearch.focus svg {
  color: rgba(255, 255, 255, 0.8) !important;
}

.headerSearch svg:hover {
  color: rgba(255, 255, 255, 1) !important;
}



.openSearch {
  font-size: 30px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 100% !important;
  padding: 5px !important;
}

.openSearch:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}



.loginBtnWrap {
  padding-right: 65px !important;
}

header .item1 {
  padding-left: 65px !important;
}

header .item1 .logoTxt {
  color: #fff;
  display: inline-block;
  padding-left: 10px;
}

header.sticky .item1 .logoTxt {
  color: #000;
}

body.dark header.sticky .item1 .logoTxt,
body.dark header.light .item1 .logoTxt {
  color: #fff;
}

header.light .item1 .logoTxt {
  color: #000;
  display: inline-block;
  padding-left: 10px;
}

.dropdown_menu {
  position: absolute;
  top: 200%;
  right: 0px;
  z-index: 100;
  background: #fafafa;
  background-image: linear-gradient(to right, #fafafa, #d6d6d6);
  width: 350px;
  padding: 15px 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
}

.dropdown_menu::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #d6d6d6;
  position: absolute;
  top: -13px;
  right: 30px;
}

.dropdown_menu.show {
  visibility: inherit;
  opacity: 1;
  top: 150%;
  transform: scale(1);
}

.dropdown_menu button {
  color: #000 !important;
  text-align: left !important;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 25px 22px !important;
  font-weight: 500 !important;
  transition: all 0.3s;
  width: 100%;
  text-transform: capitalize !important;
  position: relative;
}

.dropdown_menu button:hover {
  background: #cdcdcd;
  color: #000 !important;
}

.dropdown_menu button::before {
  content: '';
  width: 5px;
  height: 0px;
  position: absolute;
  top: 50%;
  left: 0px;
  background: #47b9d5;
  border-radius: 0px 3px 3px 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  opacity: 0;
}

.dropdown_menu button:hover::before,
.dropdown_menu a.active button::before {
  opacity: 1;
  top: 0px;
  height: 100%;
}

.dropdown_menu .info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;
}

.dropdown_menu .info * {
  color: #000 !important;
}

.dropdown_menu .info .icon_ svg,
.dropdown_menu .info .icon_ svg path {
  color: #47b9d5 !important;
}

.dropdown_menu .info h4 {
  font-weight: 500;
  font-size: 16px;
}

.dropdown_menu .info h6 {
  font-weight: 300;
  font-size: 13px;
  opacity: 0.8;
}

.dropdown_menu .info .icon_ {
  opacity: 0.9;
}

.dropdown_menu .info .icon_ svg {
  font-size: 35px !important;
}

.dropdown_menu .logoutBtn {
  color: #fff !important;
  opacity: 1 !important;
  margin-top: 10px;
}


.dropdown_menu button svg.ml-auto {
  font-size: 20px !important;
  transition: all 0.3s ease-in-out;
  position: relative;
  width: 30px;
  display: block;
}

.dropdown_menu button:hover svg.ml-auto {
  padding-left: 10px;
}

.dropdown_menu .icon {
  width: 28px !important;
  height: 28px !important;
  background: #2c2c2c !important;
  position: static !important;
  border-radius: 10px !important;
  margin-right: 10px;
}


.myAccDropdownWrapperIcon {
  width: 17px !important;
  min-width: 17px !important;
  height: 17px !important;
  background: #fff !important;
  border-radius: 50% !important;
  margin-left: 5px;
  cursor: pointer;
  transition: all 0.050s;
}

.myAccDropdownWrapperIcon svg {
  color: #47b9d5;
}

.myAccDropdownWrapperIcon.rotate {
  transform: rotate(180deg);
}

.myAccDropdownWrapper {
  margin-left: 10px;
}

.myAccDropdownTab {
  cursor: pointer;
  min-width: 40px !important;
  width: 40px;
  height: 40px !important;
  background-image: linear-gradient(to right, #47b9d5, #3895ac);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 27px;
  border-radius: 50% !important;
  padding: inherit !important;
}

.myAccDropdownTab span.rounded-circle svg {
  color: #fff !important;
}

.myAccDropdownTab span.user {
  text-transform: lowercase;
  color: #fff;
  opacity: 0.9;
  padding-left: 5px;
}

.myAccDropdownTab .icon {
  color: #fff;
  font-size: 20px !important;
  margin-left: 5px;
}

.dropdown-list .MuiMenu-paper {
  width: 250px;
  background-image: linear-gradient(to right, #16181f, #202431) !important;
  box-sizing: border-box;
  border-radius: 10px;
  right: 30px;
  top: 75px !important;
  left: 1573px !important;
}

.dropdown-list .MuiMenu-paper ul {
  padding: 15px 0px !important;
  overflow: inherit !important;
}

.dropdown-list .MuiMenu-paper ul li {
  padding: 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-list .MuiMenu-paper ul li:last-child {
  border-bottom: 0px !important;
}

.dropdown-list .MuiMenu-paper ul li a {
  color: #47b9d5 !important;
  padding: 15px 30px !important;
  font-weight: 300;
}

.dropdown-list .MuiMenu-paper ul li span {
  color: rgba(0, 0, 0, 0.8) !important;
}

.dropdown-list .MuiMenu-paper ul li svg {
  margin-right: 5px !important;
}

.dropdown-list .MuiMenu-paper ul li:hover {
  background: rgba(255, 255, 255, 0.050) !important;
}

.myAccDropdown .myAccDropdownTab span.rounded-circle {
  margin-left: auto;
}


main {
  min-height: 100vh;
  padding-bottom: 20px;
  overflow: inherit;
}

footer {
  margin-top: -78px !important;
}

.lazy-load-image-background {
  width: 100% !important;
}

.lazy-load-image-background img {
  width: 100% !important;
}

.home_slider {
  position: relative !important;
}
.btn-copied {
  background-color: transparent !important;
}
.vertical-slider .swiper-slide.v_thumb,div .horizontal-slider .swiper-slide.publicationCard_v {
  width: 241px !important;
}

.horizontal-slider .swiper-slide.h_thumb {
  width: 284px !important;
  transition: all .3s ease;
}

.horizontal-slider .home_slider .slick-slide,
.home_slider .swiper-slide {
  position: relative;
  overflow: hidden;
  display: block !important;
}


.home_slider .slick-slide .lazy-load-image-background,
.home_slider .swiper-slide .lazy-load-image-background {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.home_slider .lazy-load-image-background img {
  width: 100% !important;
}

.slick-dots {
  position: absolute;
  bottom: 20px !important;
  padding: 10px 0px;
  z-index: 100;
  left: 0%;
  width: 60%;
  width: 100%;
}

.slick-dots li {
  margin: 0px 1px !important;
}

.slick-dots li button {
  outline: none !important;
}

.slick-dots li button:before {
  color: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 50% !important;
  width: 15px !important;
  height: 15px !important;
}

.slick-dots li button:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s;
  transform: scale(0);
  opacity: 0;
}

.slick-dots li.slick-active button:after {
  opacity: 1;
  transform: scale(1);
}

.slick-dots li.slick-active button:before {
  border: 1px solid #fff !important;
}

.slick-arrow {
  transition: all 0.2s;
}

.home_slider .slick-prev {
  left: 3% !important;
  width: 60px !important;
  height: 60px !important;
}

.home_slider .slick-arrow:before {
  font-size: 32px !important;
}

.home_slider .slick-next {
  right: 3% !important;
  width: 60px !important;
  height: 60px !important;
}

.home_slider .slide {
  position: relative;
  height: auto !important;
}

.home_slider .slide .placeholder {
  position: relative;
}

.slick-track {
  margin: inherit !important;
}

.banner .lazy-load-image-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.banner .overlay {
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0;
  left: 0%;
  padding: 30px;
  padding-left: 3%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: column;
  /*background: rgba(0,0,0,0.4);*/
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4));
}

.banner .overlay .info {
  position: relative;
  top: 25%;
  width: 100%;
  padding-left: 2%;
}

.banner .overlay h3 {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 10px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60px;
}

.banner .overlay p {
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 0px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.banner .overlay div.d-flex {
  width: 100%;
}

.banner .overlay button {
  min-width: inherit !important;
  height: 45px;
  font-size: 16px !important;
  padding: 15px 20px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.banner .overlay button svg {
  font-size: 35px !important;
}

.addToWatchList {
  width: 45px !important;
  min-width: 45px !important;
  height: 45px !important;
  border-radius: 100% !important;
  background: rgba(255, 255, 255, 0.3) !important;
  margin-right: 10px !important;
}

.banner .overlay button.addToWatchList svg {
  font-size: 25px !important;
  color: #fff !important;
}

.banner .overlay button.addToWatchList:hover {
  background: rgba(255, 255, 255, 0.5) !important;
}

.addToWatchList {
  margin-right: 10px !important;
}

.overflow-inherit {
  overflow: inherit !important;
}

.banner .overlay .watchNowBtn {
  margin-right: 15px !important;
}


.container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 20px 65px !important;
}

.containerWrapper {
  width: calc(100% - 78px) !important;
  padding: 0px 20px !important;
  margin: auto;
}

.watchNowPagePlayerWrapper {
  padding-top: 140px;
  padding-bottom: 30px;
}

.watchNowPagePlayerWrapper .videoplayerwrapper {
  /* height: 70vh !important; */
  height: auto !important;
}

.watchNowPagePlayerWrapper .part2 {
  padding-right: 0px;
  padding-left: 0px;
}


.watchNowPagePlayerWrapper .part2 .placeholder img {
  width: 150px !important;
}

.videoPlayerWrapper {
  z-index: 1000000 !important;
}


.watchNowPagePlayerWrapper h3 {
  font-size: 35px;
  font-weight: bolder !important;
  letter-spacing: 3px;
  margin-bottom: 0px;
  width: 100%;
  color: #000 !important;
}

body.dark .watchNowPagePlayerWrapper h3 {
  color: #fff !important;
}

.watchNowPagePlayerWrapper .part1 {
  padding-right: 50px;
}

.watchNowPagePlayerWrapper .part1 .des {
  max-height: calc(100vh - 60vh);
  overflow-y: scroll;
  padding-right: 20px;
}

.watchNowPagePlayerWrapper .part1 .des p {
  color: #000 !important;
}

body.dark .watchNowPagePlayerWrapper .part1 .des p {
  color: #fff !important;
}

.watchNowPagePlayerWrapper .part1 .des::-webkit-scrollbar {
  background: transparent !important;
  width: 4px !important;
}

.watchNowPagePlayerWrapper .part1 .des::-webkit-scrollbar-thumb {
  background: #4f4f4f !important;
}

.watchNowPagePlayerWrapper .part1 .des::-webkit-scrollbar-track {
  background: transparent !important;
}

.watchNowPagePlayerWrapper .part1 .shareSec {
  margin-top: 30px;
}

.watchNowPagePlayerWrapper .part1 .shareSec .col_ {
  width: 40%;
  position: relative;
}

.watchNowPagePlayerWrapper .part1 .shareSec button {
  background: #25b5d8;
  padding: 8px 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  border-radius: 5px !important;
  font-weight: 600 !important;
}

.watchNowPagePlayerWrapper .part1 .shareSec button:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.shareDropdown {
  margin: 0px;
  width: 100%;
  position: absolute;
  top: 110%;
  left: 0px;
  padding: 0px;
  z-index: 100000;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: #fff;
}

body.dark .shareDropdown {
  background: #12152c !important;
}

.shareDropdown.show {
  top: 96%;
  opacity: 1;
  visibility: inherit;
}

.shareDropdown li {
  width: 100%;
  list-style: none;
}

.shareDropdown li button {
  text-align: left !important;
  justify-content: left !important;
  border: 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.050) !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #272727 !important;
  border-radius: 0px !important;
}

body.dark ul.shareDropdown li button {
  color: #fff !important;
  font-weight: 300 !important;
}

body.dark .shareDropdown li button svg {
  color: #fff !important;
}

body.dark .watchNowPagePlayerWrapper .part1 .shareSec button {
  font-weight: 400 !important;
}



.shareDropdown li button * {
  font-weight: 600 !important;
}

.shareDropdown li:last-child button {
  border-bottom: 0px !important;
}

.shareDropdown li button:hover {
  background: #e7e7e7 !important;
}

body.dark .shareDropdown li button:hover {
  background: #0d0f1e !important;
  color: #25b5d8 !important;
}

body.dark .shareDropdown li button:hover * {
  color: #25b5d8 !important;
}

.shareDropdown li button svg {
  font-size: 22px !important;
  margin-right: 5px !important;

}

.thumb_slider_section {
  position: relative;
  padding: 15px 0px;
}

.thumb_slider_section .thumb_slider_sectionWrapper {
  padding: 20px 65px;
}

.thumb_slider_section .bg .thumb_slider_sectionWrapper {
  display: flex !important;
}

.thumb_slider_section .bg .thumb_slider_sectionWrapper .swiper {
  width: 50%;
  margin-left: auto !important;
}

.thumb_slider_section .featuredRow {
  background: url(../images/bg.jpg) repeat-x top center;
  padding: 25px 0px;
}


.speacialProgrames {
  background-size: cover !important;
  padding: 35px 0px;
  display: flex;
}

.speacialProgrames .thumb_slider_sectionWrapper {
  width: 80% !important;
  margin-left: auto !important;
}

.thumb_slider_section:hover {
  z-index: 100 !important;
}

.thumb_slider_section .swiper,
.thumb_slider_section .slick-list {
  overflow: inherit !important;
}

.thumb_slider_section .swiper-slide,
.thumb_slider_section .slick-slide {
  transition: all 0.0s;
  position: relative;
}

.thumb_slider_section .slick-slide {
  padding: 0px 10px;
}

.thumb_slider_section .swiper-slide:hover {
  z-index: 100000;
}


body.light .thumb_slider_section .title {
  color: #000;
}

body.dark .thumb_slider_section .title {
  color: #fff;
}

.thumb_slider_section .title {
  font-size: 22px;
  opacity: 0.9;
  margin-bottom: 25px;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 0px !important;
}

.thumb_slider_section .title a {
  margin-left: auto;
  color: #47b9d5;
  font-size: 17px;
  font-weight: 600;
}

.thumb_slider_section .title a:hover {
  color: #000;
}

body.dark .thumb_slider_section .title a:hover {
  color: #fff !important;
}


.thumb_slider_section .music_channels .item .overlay_ {
  display: none !important;
}


.item.first {
  border: 2px solid transparent !important;
  transition: all 0.1s ease-in-out !important;
}

.item.first .zoomContainer {
  left: 58% !important;
  transform: translate(-50%, -70%, -50%, -50%) scale(1.1) !important;
}

.item.first .itemBox {
  opacity: 0 !important;
}


.item.last {
  border: 2px solid transparent !important;
  transition: all 0.1s ease-in-out !important;
}

.item.last .zoomContainer {
  right: 62% !important;
  transform: translate(-50%, -10%, -50%, -50%) scale(1.1) !important;
  left: 40% !important;
}

.item.last .itemBox {
  opacity: 0 !important;
}

.item.active {
  border: 2px solid transparent !important;
}


.zoomContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) !important;
  width: 100%;
  height: auto;
  z-index: 1000000;
  overflow: hidden;
  border-radius: 6px;
  padding: 0px;
  background: #fafafa;
  background-image: linear-gradient(#fafafa, #e2e2e2);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  border: 0px solid #141722;
  padding-bottom: 15px !important;
  opacity: 0;
  transition: all 0.4s;
}

.item.v_thumb .zoomContainer {
  top: -2%;
}

.zoomContainer:hover {
  opacity: 1;
}

/* .thumb_slider_section.last .zoomContainer{top:inherit; bottom: 0px;}
.thumb_slider_section.sec_last .zoomContainer{top:inherit; bottom: -60%;} */

.zoomContainer .videoContainer {
  border-radius: 5px;
  position: relative;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
}

.zoomContainer .videoContainer.vert {}

.zoomContainer .videoContainer.vert .placeholderImg {
  height: 135px;
  object-fit: cover;
}

.zoomContainer .videoContainer:after {
  content: '';
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: -2px;
  left: 0px;
  background-image: linear-gradient(180deg, transparent, #e2e2e2);
  z-index: 1000;
}

.zoomContainer .videoContainer video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.zoomContainer .videoContainer .playerContainer_Wrapper {
  width: 100% !important;
  height: 100% !important;
}

.playercontainer_wrapper .loader {
  position: absolute !important;
  top: 0px !important;
  height: 100% !important;
}

.playercontainer_wrapper .loader svg {
  color: #fff !important;
}

.playercontainer_wrapper .loader img {
  width: 100px;
}


.zoomContainer .muteBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 27px !important;
  height: 27px !important;
  width: 27px !important;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 50%;
}

.zoomContainer .muteBtn svg {
  color: #fff;
  font-size: 18px;
}

.zoomContainer .info {
  padding: 0px 15px;
}

.zoomContainer .info a {
  color: #000;
  font-size: 14px;
}

.zoomContainer .info a svg {
  font-size: 20px !important;
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.zoomContainer .info .icons svg {
  color: #000;
  font-size: 22px;
}

.zoomContainer .info h4 {
  margin-top: 5px !important;
}

.zoomContainer .info h4,
.zoomContainer .info h4 a {
  color: #49b4d2;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.3s;
}

.zoomContainer .info h4:hover,
.zoomContainer .info h4 a:hover,
.zoomContainer .info a:hover,
.zoomContainer .info svg:hover {
  color: #49b4d2;
}

.zoomContainer .info h4 a:after,
.zoomContainer .readMore:after {
  background: #49b4d2 !important;
}

.zoomContainer .info p {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0px;
  min-height: 60px;
  margin-top: 0px;
}

.zoomContainer .info .des {
  min-width: 20px;
  max-height: 50px;
  overflow: hidden;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.zoomContainer .info .des.show {
  max-height: 80px !important;
}

.zoomContainer .info .des.show.des.show::after {
  display: none !important;
}

.zoomContainer .info .des p {
  color: #000 !important;
  opacity: 1 !important;
}

.zoomContainer .info .des::after {
  content: '';
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-image: linear-gradient(180deg, transparent, #141722);
  display: none;
}




.item .zoomContainer .placeholder img {
  width: 100% !important;
  height: auto !important;
  opacity: 1 !important;
  left: 0px !important;
  z-index: 100;
}

.zoomContainer .placeholderImg {
  opacity: 0 !important;
}

.zoomContainer .readMore {
  font-size: 12px !important;
  font-weight: 500;
  opacity: 1;
}

.zoomContainer .readMore::after {
  background: #000 !important;
}


.zoomContainer .readMore:hover {
  opacity: 1;
  color: #000 !important;
}

.MuiSpeedDial-root {
  position: inherit !important;
  flex-direction: row !important;
}

.MuiSpeedDial-actions {
  position: absolute !important;
  top: -72% !important;
  right: 13px;
}

.MuiSpeedDial-actions button {
  margin: 3px !important;
  background: #fff !important;
  width: 22px !important;
  height: 22px !important;
  min-height: 22px !important;
  min-width: 22px !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 34%), 0 1px 2px rgb(0 0 0 / 34%) !important;
}

.MuiSpeedDial-actions button svg {
  color: #171616;
  font-size: 14px !important;
}

.MuiSpeedDial-actions button:hover svg,
.MuiSpeedDial-actions button svg:hover {
  color: #47b9d5 !important;
}

.MuiSpeedDial-root .MuiButtonBase-root {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  background: transparent;
}

.MuiSpeedDial-root .MuiButtonBase-root:hover {
  background: transparent;
}



.fb {
  color: #3b5998 !important;
}

.whatsapp {
  color: #28a92c !important;
}

.linkedin {
  color: #2e60bd !important;
}

.tw {
  color: #4294e7 !important;
}

.telegram {
  color: #4497d0 !important;
}

.item {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: all 0.4s ease-in-out !important;
}

.item:hover {
  border: 2px solid #47b9d5;
  transform: scale(1.1);
  z-index: 100000;
}

.item .lazy-load-image-background {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  height: 100%;
  transition: all 0.3s !important;
  border-radius: 5px;
  overflow: hidden;
}




.item .overlay_ {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  /*background-image: linear-gradient(rgba(0,0,0,0.0),#161924);*/
  background: none !important;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 15px !important;
  transition: all 0.3s ease-in-out;
}

.item:hover .overlay_ {
  opacity: 1;
  background: rgba(0, 0, 0, 0.6) !important;
}

.item .topPicsItem {
  width: 100%;
  height: 105px;
  background: #16181f;
  padding: 15px;
}

.item .topPicsItem h4 {
  font-size: 14px;
  color: #c6c6c6;
  font-weight: 300;
  line-height: 20px;
}

.item .topPicsItem p {
  font-size: 11px;
  color: #a6a6a6;
  font-weight: 300;
  margin-bottom: 0px;
}


.marquee-container {
  overflow: hidden !important;
}

.item .overlay_ .bottomSec {
  position: absolute;
  bottom: 0px;
  left: 5%;
  width: 90%;
  height: 50px;
  overflow: hidden !important;
  z-index: 100000;
}

.item .overlay_ .marquee-container {
  bottom: 10px;
  width: 85%;
}

.item .overlay_ .bottomSec .marquee {
  padding: 0px 10px 0px 0px !important;
}

.item .overlay_ .bottomSec span {
  font-weight: 400 !important;
  padding: 0px 7px !important;
  opacity: 0.9;
  text-transform: uppercase;
  font-size: 13px;
}

.item .remove {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 100000;
  background: #47b9d5;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.item .remove svg {
  font-size: 16px;
}

.item .remove:hover {
  color: rgb(251, 207, 207) !important;
}



.item .des {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background: rgba(24, 39, 56, 0.6);
  backdrop-filter: blur(30px);
  z-index: 100;
  padding: 15px 10px;
  padding-top: 25px;
  transition: all 0.3s ease-in-out;
}

.item .des h6 {
  font-size: 14px;
  color: #00FFFF;
}

.item .des h6.new {
  font-size: 14px;
  color: #EF2F62;
}

.item .des h4,
.item .des h4 * {
  font-size: 14px !important;
  color: #fff;
  margin-bottom: 5px;
}

.item .des h4 {
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding-right: 15px;
}

.item .des h4::-webkit-scrollbar {
  width: 5px !important;
}

.item .des h4::-webkit-scrollbar-thumb {
  background: #b9b9b9;
}

.item .des p div,
.item .des p p {
  width: 100%;
}

.item .des p,
.item .des h4 p {
  font-size: 13px !important;
  color: #fff;
  line-height: 20px;
  opacity: 0.8;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;

}

.item .des .playIcon {
  width: 35px;
  height: 35px;
  background: #25B5D8;
  position: absolute;
  top: -18px;
  right: 18px;
}

.item .des .playIcon svg {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}



.radioCardCarousel .item {
  overflow: hidden;
  background: rgba(24, 39, 56, 0.6);
  position: relative;
}

.radioCardCarousel .item .itemBox.v {
  height: auto;
}

.radioCardCarousel .item .itemBox.v .des {
  bottom: -100%;
}

.radioCardCarousel .item:hover .des {
  bottom: 0px !important;
}


.video_played {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  overflow: hidden;
  bottom: 15px;
  left: 5%;
  width: 90%;
}

.video_played span {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #f42717;
  height: 100%;
  transition: all 3s;
}



.item .placeholder img {
  opacity: 0.3 !important;
  visibility: inherit !important;
  width: 70px;
  position: relative;
  left: -10px;
}

.item .placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
}

/* .item .placeholder .img {
  filter: grayscale(100%);
} */

.placeholderImg {
  opacity: 0 !important;
}

.item .MuiSkeleton-rectangular {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100% !important;
}

.video-play-button {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 110px;
  /* background: #fa183d; */
  border-radius: 50%;
  zoom: 60%;
  position: absolute;
  top: 48%;
  left: 48%;
  pointer-events: none !important;
  color: #fff;
  font-size: 80px !important;

}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(rgba(255, 255, 255, 0.4), 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}








.thumb_slider_section .slick-next::before,
.thumb_slider_section .slick-prev::before,
.sliderArrows::before {
  font-size: 35px !important;
  display: none !important;
}

.sliderArrows::after {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold;
}

.thumb_slider_section .swiper-button-prev {
  left: 40px !important;
}

.thumb_slider_section .swiper-button-next {
  right: 40px !important;
}

.sliderArrows,
.slick-arrow {
  padding: 3px !important;
  border-radius: 50% !important;
  position: absolute;
  top: 49% !important;
  margin: 0px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: FontAwesome !important;
  z-index: 100;
  opacity: 0 !important;
}

.sliderArrows {
  z-index: 1000;
  cursor: pointer;
  zoom: 100%;
  transition: all 0.2s ease-in-out;
}
div .sliderArrows.disabled{
  display: none !important;
}
.sliderArrows:hover {
  background: #39a1bb !important;
}

.thumb_slider_section:hover .sliderArrows,
.thumb_slider_section:hover .slick-arrow {
  opacity: 1 !important;
}

.slick-prev:before,
.slick-next:before,
.sliderArrows {
  font-family: FontAwesome !important;
  font-size: 15px ​ !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.slick-prev:before,
.swiper-button-prev::before {
  content: "\f104" !important;
  color: #000;
}

.slick-next:before,
.swiper-button-next::before {
  content: "\f105" !important;
  color: #000;
}

.slick-arrow.slick-disabled {
  background: rgba(255, 255, 255, 0.4) !important;
}

.slick-arrow.slick-disabled:before {
  opacity: 0.7 !important;
}

.swiper-button-prev,
.slick-prev {
  background: #343537 !important;
}

.swiper-button-next,
.slick-next {
  background: #343537 !important;
}

.swiper-slide .playerWrapperContainer {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0px;
  left: 0px;
}

.swiper-slide .playerWrapperContainer video {
  object-fit: fill !important;
}

.home_slider {
  position: relative;
}

.home_slider::after {
  content: '';
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-image: linear-gradient(rgba(42, 44, 66, 0), #2a2c42);
  z-index: 100;
  pointer-events: none;
}

.home_slider .swiper-pagination {
  z-index: 1000;
}

.home_slider .swiper-button-prev {
  left: 3% !important;
}

.home_slider .swiper-button-next {
  right: 3% !important;
}

.home_slider .swiper-button-prev,
.home_slider .swiper-button-next {
  background-image: none !important;
  opacity: 0 !important;
  transition: all 0.2s ease-in-out !important;
  background: none !important;
}

.home_slider:hover .swiper-button-prev,
.home_slider:hover .swiper-button-next {
  opacity: 1 !important;
}

.home_slider .swiper-button-prev::before,
.home_slider .swiper-button-next::before {
  display: none !important;
}

.home_slider .swiper-button-prev::after,
.home_slider .swiper-button-next::after {
  color: #fff !important;
  font-size: 60px !important;
}

.swiper-pagination {
  bottom: 20px !important;
}

.swiper-pagination-bullet {
  transition: all 0.2s ease-in !important;
  background: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #49b4d8 !important;
  width: 20px !important;
  transform: translateX(0);
  border-radius: 3px !important;
}

.prehome_slider .swiper-button-prev {
  left: 3% !important;
}

.prehome_slider .swiper-button-next {
  right: 3% !important;
  background: none !important;
}

.prehome_slider .swiper-button-prev,
.prehome_slider .swiper-button-next {
  background-image: none !important;
  background: none !important;
  opacity: 0 !important;
  transition: all 0.2s ease-in-out !important
}

.prehome_slider:hover .swiper-button-prev,
.prehome_slider:hover .swiper-button-next {
  opacity: 1 !important;
}

.prehome_slider .swiper-button-prev::before,
.prehome_slider .swiper-button-next::before {
  display: none !important;
}

.prehome_slider .swiper-button-prev::after,
.prehome_slider .swiper-button-next::after {
  color: #fff !important;
  font-size: 40px !important;
}

.prehome_slider .swiper-pagination {
  bottom: 10px !important;
}

.prehome_slider .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
}

.swiper-scrollbar-drag {
  background: transparent !important;
}

.loader {
  position: fixed;
  top: 75px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader span {
  width: 50px !important;
  height: 50px !important;
}

body.light footer {
  background: #fff;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

body.dark footer {
  background: #12152c;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #636364;
}


footer {
  padding: 8px 0px;
  overflow: hidden;
}

.footerContainer {
  align-items: center;
  display: flex;
}

.footer_logo_wrapper {
  width: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

body.light .footer_logo_wrapper span {
  color: #000;
}

body.dark .footer_logo_wrapper span {
  color: #fff;
}

footer .footer_logo {
  width: 50px;
}

.footer_nav ul {
  padding-left: 0%;
  position: relative;
  left: 0px;
}

.footer_nav ul li {
  margin: 0px 0px !important;
  padding: 0px 15px;
  position: relative;
  outline: none !important;
}

body.light .footer_nav ul li:after {
  background: #000;
}

body.dark .footer_nav ul li:after {
  background: #fff;
}

.footer_nav ul li:after {
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 14px;
  left: 5px;
  border-radius: 50%;
}

.footer_nav ul li:before {
  display: none;
}

body.light .footer_nav ul li a {
  color: #000;
}

body.dark .footer_nav ul li a {
  color: #fff;
}

.footer_nav ul li a {
  font-size: 15px;
  position: relative;
  display: block;
  padding: 5px 0px;
  font-weight: 500;
  outline: none !important;
}

body.dark .footer_nav ul li a {
  font-weight: 400 !important;
}

.footer_nav ul li a:after {
  content: '';
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0px;
  top: inherit !important;
  left: 0px;
  background: #49b4d8;
  transition: all 0.3s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.footer_nav ul li a:hover:after {
  width: 100%;
}

footer .item3 img:hover {
  opacity: 0.8;
}

footer .item3 {
  padding-right: 65px !important;
}

footer .item1 {
  padding-left: 65px !important;
  display: flex !important;
  flex-direction: column !important;
}

footer .item1 .logoTxt {
  font-size: 15px;
}

body.light .copyright p {
  color: #000;
}

body.dark .copyright p {
  color: #fff;
}

body.dark .copyright {
  background: #12152c;
}



.copyright p {
  margin-bottom: 0px;
  font-size: 12px;
}

.loginModal,
.preHomeModal {
  z-index: 1000000 !important;
}

.preHomeModal .MuiPaper-elevation {
  overflow: hidden !important;
}

.loginModal .modal-body {
  padding: 0px !important;
  border-top: 0px !important;
}

.loginModal .modal-body .txt-light2 {
  color: #000 !important;
}

body.dark .loginModal .modal-body .txt-light2 {
  color: #fff !important;
}

.loginModal .modal-body .txt-light {
  color: #000 !important;
  opacity: 0.7;
}

body.dark .loginModal .modal-body .txt-light {
  color: #fff !important;
}

.loginModal .modal-body .cursor.txt-light {
  opacity: 1 !important;
  font-weight: 500 !important;
}

.loginModal .MuiDialog-paper {
  padding: 0px;
}

.MuiSkeleton-rectangular {
  background-color: rgba(0, 0, 0, 0.050) !important;
  width: 100% !important;
}

.css-1ibsgv2-MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 1), transparent) !important;
  opacity: 1 !important;
}

.item .MuiSkeleton-rectangular {
  opacity: 1 !important;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
}

.MuiDialog-paper {
  padding: 15px 25px;
  background: #12152c !important;
  width: 450px;
  height: auto;
  border: 2px solid #191d3c;
  border-radius: 15px !important;
  overflow: hidden;
}

.loginModal {
  position: relative;
}

.loginModal .logo {
  width: 150px;
}

.loginModal form {
  padding: 25px;
  padding-top: 0px;
}

.loginModal form p.txt-light {
  margin-top: 5px !important;
}

.loginModal form .form-group .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 300 !important;
}

.loginModal form .form-group.showPlaceholder fieldset {
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 10px !important;
}

.loginModal form .form-group.showPlaceholder .MuiInputLabel-formControl {
  color: rgba(255, 255, 255, 0.3);
}

form .form-group.showPlaceholder {
  position: relative;
}

form .form-group.showPlaceholder .placeholderTitle {
  position: absolute;
  top: 18px;
  left: 15px;
  z-index: 1000;
  color: #fff;
  opacity: 0.5;
  font-size: 14px;
  pointer-events: none;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background: #c5c5c5 !important;
  color: #fff !important;
}

form .form-group.showPlaceholder fieldset legend {
  visibility: inherit !important;
  opacity: 1 !important;
  max-width: inherit !important;
  height: auto !important;
}

form .form-group.showPlaceholder fieldset legend span {
  color: #000 !important;
  visibility: inherit !important;
  opacity: 1 !important;
}

.loginModal form .loginWith .tab {
  width: 45px;
  height: 45px;
  background: #1e1c1a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.loginModal form .loginWith .tab i {
  font-size: 25px;
  color: #4850a5;
}

.loginModal form .loginWith .social {
  color: #fff;
}

.loginModal form .bottomPart {
  font-size: 13px;
  font-weight: 300 !important;
  opacity: 0.7;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: #47b9d5 !important;
}

.css-ahj2mt-MuiTypography-root {
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  opacity: 0.8;
}

.loginModal .close_ {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  min-width: 30px !important;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  z-index: 100;
}

.loginModal .close_ svg {
  color: #000 !important;
  font-size: 25px !important;
}

.loginModal .close_:hover {
  background: #fff !important;
}

.loginModal .MuiDialog-paper {
  background: #fff !important;
}

body.dark .loginModal .MuiDialog-paper {
  background: #182738 !important;
}

body.dark .loginModal .MuiDialog-paper .MuiGrid-root img {
  opacity: 0.2 !important;
}

body.dark .css-ahj2mt-MuiTypography-root {
  color: #fff !important;
}

body.dark .loginModal .modal-body .txt-light {
  margin-top: 20px !important;
}

body.dark .loginModal .css-19sxs0w-MuiGrid-root {
  margin-top: -35px !important;
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: rgba(0, 0, 0, 0.7) !important;
}

body.dark .MuiInputBase-input,
body.dark .MuiInputBase-input {
  color: #fff !important;
}

.loginModal button.bg-red {
  height: 60px;
}

.MuiSnackbar-root {
  z-index: 10000000 !important;
}

.otp_box .form-control {
  border-radius: 3px !important;
  background: rgba(0, 0, 0, 0.080) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 300;
  width: 45px;
  height: 45px;
  font-size: 14px;
  outline: none !important;
  text-align: center;
  margin: 0px 5px !important;
  font-weight: 600;
}

body.dark .otp_box .form-control {
  color: #fff !important;
}

.otp_box .form-control:focus {
  box-shadow: none !important;
}

.langModalList {
  display: flex;
  padding: 15px 35px !important;
  flex-wrap: wrap;
}

.langModalList li {
  width: 47%;
  flex: 0 0 47%;
  display: flex;
  margin: 5px 5px !important
}

.langModalList li button {
  background: #47b9d5;
  width: 100%;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: capitalize !important;
}

.langModalList li button:hover {
  border: 1px solid #2488a0;
  background: #1e778e;
}

.listingPage {
  padding: 20px 0px;
  margin: auto;
  margin-top: 80px;
}

.listingPage .hd,
.inner_hd h3 {
  font-weight: 500;
  font-size: 26px;
  margin: 20px 0px;
  color: #000 !important;
}

.inner_hd h3 * {
  color: #000 !important;
}

body.dark .listingPage .hd,
body.dark .inner_hd h3 {
  color: #fff !important;
}

body.dark .inner_hd h3 * {
  color: #fff !important;
}

.listingPage .data {
  padding: 0px 0px;
}

.listingPage .data .item {
  margin-bottom: 15px;
  height: auto;
  background-color: transparent !important;
  padding: 0px;
}

.listingPage .data .item .MuiSkeleton-rectangular {
  padding: 21% 0%;
  height: auto;
  width: 100% !important;
}

.listingPage .data .item:hover {
  z-index: 10000;
}



/*---loadeable components css start here----*/
.homeLoadable {
  width: 100%;
  height: auto;
  position: relative;
}

.homeLoadable .container-fluid {
  padding: 0px 65px;
}

.homeLoadable img {
  opacity: 0;
}

.homeLoadable .img {
  opacity: 0.2 !important;
  visibility: inherit !important;
  width: 200px;

}

.homeLoadable .placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100%;
  height: 100% !important;
  padding-top: 100px;
}

.homeLoadable .thumb_slider_section .item img {
  width: 120px;
}

/*---loadeable components css ends here----*/



.inner_banner {
  width: 100%;
  height: 200px;
  background: url(../images/profileBanner.png) repeat-x top left;
  overflow: hidden;
  position: relative;
  -webkit-animation: slide 30s linear infinite;
  box-sizing: border-box;
  margin-top: 100px;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -512px 0px;
  }
}


@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -512px 0px;
  }
}


.MuiSnackbar-root {
  top: 100px !important;
}

.inner_banner::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.2);
}

.inner_banner * {
  z-index: 100;
  position: relative;
}

.inner_banner h3 {
  font-size: 35px;
  font-weight: 300;
}

.profile_setting {
  padding-top: 120px;
  margin: auto;
}

.inner_hd {
  margin-bottom: 25px;
}

.profileForm {
  padding: 20px 60px;
  border-radius: 8px;
}

.profile_setting .profile {
  position: relative;
  z-index: 100;
}

.profile_setting .profile span.rounded-circle {
  width: 100px;
  height: 100px;
  background: #47b9d5;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 7px !important;
  position: relative;
}

.profile_setting .profile span.rounded-circle svg {
  color: #787878 !important;
  font-size: 50px !important;
}

.profile_setting .profile span.rounded-circle {
  overflow: hidden;
}

.profile_setting .profile span.rounded-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_setting .profile .edit {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  bottom: 5px;
  right: 5px;
  background: #464646 !important;
  cursor: pointer;
}

.profile_setting .profile span.edit.rounded-circle svg {
  font-size: 14px !important;
  color: #fff !important;
  opacity: 0.7;
}

.profile_setting .profile .edit input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}

.profile_setting .profile h4 {
  font-size: 22px;
  color: #000 !important;
}

.profile_setting .profile h4 svg {
  color: #000 !important;
  opacity: 0.5;
}


.form-group label.Mui-focused {
  color: #000 !important;
  font-weight: 300 !important;
  opacity: 0.8;
}


.form-group .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 300 !important;
}

.form-group fieldset {
  border-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
}

body.dark .form-group fieldset {
  border-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 10px !important;
}

.form-group .MuiInputLabel-formControl {
  color: #000;
}

body.dark .form-group .MuiInputLabel-formControl {
  color: #fff !important;
}


body.dark .form-group .MuiInputLabel-formControl {
  color: #fff !important;
}

.profile_setting .form {
  width: 400px;
  margin: auto;
}

.profile_setting .form .form-group {
  margin-bottom: 30px;
}

.MuiMenu-paper {
  background: #fafafa !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

body.dark .MuiMenu-paper {
  background: #182738 !important;
  border: 1px solid rgba(255, 25, 255, 0.1) !important;
}

.MuiSelect-icon {
  color: #000 !important;
}

body.dark .MuiSelect-icon {
  color: #fff !important;
  opacity: 0.8 !important;
}

body.dark .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.9) !important;
}

body.dark .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.6) !important;
}

body.dark .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.MuiMenu-paper ul {
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden !important;
}

.MuiMenu-paper ul::-webkit-scrollbar-thumb {
  background: #2f2f2f !important;
}

.MuiMenu-paper ul::-webkit-scrollbar-track {
  background: none !important;
}

.MuiMenu-paper ul li {
  padding: 10px 15px !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.8);
}

body.dark .MuiMenu-paper ul li {
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 600 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background: rgba(255, 255, 255, 0.1) !important;
}

.MuiMenu-paper ul li:hover {
  background: rgba(0, 0, 0, 0.050) !important;
}

body.dark .MuiMenu-paper ul li:hover {
  background: rgba(0, 0, 0, 0.080) !important;
}

.MuiMenu-paper ul li a {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 300 !important;
}

body.dsrk .MuiMenu-paper ul li a {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.light .MuiSelect-select {
  color: rgba(0, 0, 0, 0.8) !important;
}

body.dark .MuiSelect-select {
  color: rgba(255, 255, 255, 0.8) !important;
}

.banner {
  overflow: hidden;
  position: relative;
}


/* .banner .imgWrapper{-webkit-animation: zooming 14s ease-in-out infinite alternate;
  animation: zooming 14s ease-in-out infinite alternate;} */

@-webkit-keyframes zooming {
  0% {
    -webkit-transform: scale(1.0);
  }

  100% {
    -webkit-transform: scale(1.1);
  }
}


@keyframes zooming {
  0% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(1.1);
  }
}



@-webkit-keyframes zoomingThumb {
  0% {
    -webkit-transform: scale(1.0);
  }

  100% {
    -webkit-transform: scale(1.2);
  }
}


@keyframes zoomingThumb {
  0% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(1.2);
  }
}



.videoInfo {
  padding: 40px 0px;
  padding-bottom: 0px;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

body.dark hr {
  border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.videoInfo h2 {
  font-weight: 300;
}

.videoInfo p {
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  background: #272823;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}


.plansTable {
  padding: 60px 0px;
}

.plansTable .plansTableWrapper {
  width: 60%;
  height: auto;
  margin: auto;
}

.plansTable .plansTableWrapper .slick-slide {
  padding: 0px 15px;
}

.plansTable .plansTableWrapper .column {
  background: none !important;
  border: 1.5px solid #434343;
  overflow: hidden;
  height: auto;
  border-radius: 15px;
  background: #141414 !important;
  padding-top: 20px;
}

.plansTable .plansTableWrapper .column.act {
  border: 1.5px solid #47b9d5;
}

.plansTable .plansTableWrapper .column .head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background: none !important;
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}

.plansTable .plansTableWrapper .column.act .head {
  color: #47b9d5 !important;
}

.plansTable .plansTableWrapper .column:hover .head {
  color: #47b9d5 !important;
}


.plansTable .plansTableWrapper .column .body {
  padding-bottom: 30px !important;
}

.plansTable .plansTableWrapper .column .body p {
  text-align: center;
}

.plansTable .plansTableWrapper .column .body .list {
  margin-bottom: 25px !important;
}

.plansTable .plansTableWrapper .column .body .list li {
  margin-bottom: 10px;
}

.plansTable .plansTableWrapper .column .body .list li svg {
  margin-right: 10px;
  color: #fff !important;
  font-size: 18px !important;
}

.plansTable .plansTableWrapper .column.act .body .list li svg {
  margin-right: 10px;
  color: #EA3A15 !important;
  font-size: 18px !important;
}

.plansTable .plansTableWrapper .column .body .list li span {
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.6);
}

.plansTable .plansTableWrapper .column .body button {
  padding: 5px 30px !important;
  font-size: 20px !important;
  border: 1px solid #fff !important;
  background: #fff !important;
  color: #000 !important;
}

.plansTable .plansTableWrapper .column.act .body button,
.plansTable .plansTableWrapper .column:hover .body button {
  padding: 5px 30px !important;
  font-size: 20px !important;
  border: 1px solid #47b9d5 !important;
  background: #47b9d5 !important;
  color: #fff !important;
}

.ticketTTable {
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 45px auto !important;
  padding: 0px !important;
  max-width: 80%;
  padding-bottom: 25px !important;
}


.ticketSection table {
  margin: 0px !important;
}

.ticketSection table thead tr {
  background: #292b34 !important;
}

.ticketSection table thead th {
  color: #fff;
  font-weight: 500 !important;
  padding: 14px 20px;
  border-bottom: 0px solid rgba(255, 255, 255, 0.2) !important;
  font-size: 18px;
  background: transparent !important;
}

.ticketSection table.table-hover tr:hover td {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.050);
}

span.bg-success {
  background-color: #36ae95 !important;
}

.ticketSection table td {
  color: rgba(255, 255, 255, 0.6);
  padding: 14px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  font-weight: 300;
  background: transparent !important;
}

.ticketSection table tr {
  background: #1a1a1a;
}

.ticketSection table tr:hover td {
  color: rgba(255, 255, 255, 0.9) !important;
}

.ticketSection table td .badge {
  padding: 8px 15px;
  font-weight: 500;
  font-size: 14px;
}

.ticketSection table tbody {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ticketSection table td button {
  min-width: inherit !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.ticketSection table td button svg {
  font-size: 20px !important;
}

.ticketSection .arrows span {
  color: #cccccc !important;
}

.ticketSection .arrows .border-effect:hover,
.ticketSection .arrows .border-effect:hover svg {
  color: #47b9d5 !important;
}


.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.ticketTTable h5 {
  margin: 0px !important;
  cursor: pointer;
}

.ticketTTable .col.border-right {
  padding-right: 20px;
}

.ticketPage {
  border-radius: 0px !important;
}


.myList .col-md-4 .img {
  margin-right: 25px;
  overflow: hidden;
  border-radius: 10px !important;
  cursor: pointer;
}

.myList .col-md-4 .img:hover img {
  transform: scale(1.1);
}

.myList .col-md-4 .img img {
  transition: all 0.3s;
}

.myList .col-md-4 .info button {
  min-width: inherit !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.myList .col-md-4 .info a,
.myList .col-md-4 .info a h3 {
  color: #49b4d8 !important;
}

.myList .col-md-4 .info button svg {
  font-size: 20px !important;
}

.contactPage button {
  width: 50% !important;
  height: 55x !important;
  border-radius: 7px !important;
}

.myList .col-md-4 {
  padding: 25px;
}

.myList .col-md-4 .item_ {
  max-width: 40%;
}

.myList .col-md-4 .info {
  max-width: 60%;
  padding-left: 30px;
}

.myList .col-md-4 .info .my-3 {
  margin-bottom: 0px !important;
}

.myList .col-md-4 .info h5 {
  font-size: 16px !important;
  color: #000 !important;
}


.myListWrapper {
  max-width: 90%;
  overflow: inherit;
  margin: auto !important;
}

.myList .col-md-4 h3 {
  cursor: pointer;
  font-weight: 400 !important;
  opacity: 0.8;
  transition: all 0.3s;
  font-size: 18px !important;
  line-height: 26px !important;
}

.myList .col-md-4 h3:hover {
  color: #eaa33b !important;
  opacity: 1 !important;
}

.myList .item img {
  z-index: 100;
  position: relative;
  margin-right: 0px !important;
}

.myList .lazy-load-image-background {
  position: absolute;
}

.myList .item .MuiSkeleton-rectangular {
  position: absolute;
  height: 100% !important;
  top: 0px;
  left: 0px;
  width: 100%;
}

.myList .item .lazy-load-image-background img {
  height: 100%;
}

.staticData p,
.staticData ul li {
  color: #000 !important;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

body.dark .staticData p,
body.dark .staticData ul li {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark .staticData a {
  color: #47b9d5 !important;
}

.staticData ul {
  padding: 0px;
  margin-bottom: 25px;
}

.staticData ul li {
  list-style: none;
  padding-left: 25px;
  position: relative;
  margin-bottom: 7px;
}

.staticData ul li::before {
  content: "\f058";
  width: 20px;
  height: 20px;
  font-family: FontAwesome;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #47b9d5;
}

.staticData strong {
  font-weight: 600 !important;
}

.staticData h4 {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 15px;
}

body.dark .staticData h4 {
  color: #fff !important;
}

.aboutBox {
  padding: 45px 0px;
}

.aboutBox .card {
  background: #2d2d2d !important;
  padding: 40px 55px !important;
  border-radius: 10px;
  margin: 0px 15px;
  flex: 1;
  position: relative;
}

.aboutBox .card h4 {
  color: #fff;
  width: 100%;
}

.aboutBox .card p {
  margin-bottom: 0px !important;
  width: 100%;
}



.aboutBox .card:hover {
  background: #252525 !important;
}

.aboutBox .card::before,
.aboutBox .card::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #47b9d5;
}



.aboutBox .card:hover {
  -webkit-animation: mover .7s infinite alternate;
  animation: mover .7s infinite alternate;
  border-radius: 0px !important;
}

.aboutBox .card::before {
  left: 50%;
  top: 0;
  transition-duration: 0.3s;
}

.aboutBox .card::after {
  left: 50%;
  bottom: 0;
  transition-duration: 0.3s;
}


.aboutBox .card:hover::before,
.aboutBox .card:hover::after {
  left: 0;
}

.aboutBox .card:hover span::before,
.aboutBox .card:hover span::after {
  top: 0;
}

.aboutBox .card:hover::before,
.aboutBox .card:hover::after {
  width: 100%;
}

.aboutBox .card h4::before,
.aboutBox .card h4::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #47b9d5;
}

.aboutBox .card:hover h4::before,
.aboutBox .card:hover h4::after {
  height: 100%;
}

.aboutBox .card h4::before {
  left: 0;
  top: 50%;
  transition-duration: 0.3s;
}

.aboutBox .card h4::after {
  right: 0;
  top: 50%;
  transition-duration: 0.3s;
}

.aboutBox .card:hover h4::before,
.aboutBox .card:hover h4::after {
  top: 0;
}


.aboutRow2 {
  padding: 25px 0px;
  margin: 45px 0px 70px 0px;
  position: relative;
}

.aboutRow2 p {
  font-size: 20px;
  color: #fff !important;
  font-weight: 400;
  line-height: 38px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
}


.tvImg {
  width: 100%;
  height: 264px;
  position: absolute;
  top: -40%;
  left: 8%;
  background: url(../images/tv.png) no-repeat top center;
  -webkit-animation: mover .7s infinite alternate;
  animation: mover .7s infinite alternate;
}

.tvImg img {
  width: 150px;
  top: -20px;
  position: relative;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-7px)
  }
}

@keyframes mover {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-7px)
  }
}

.home-row3:hover img,
.home-row4:hover img,
.home-row5:hover img {
  -webkit-animation: mover .7s infinite alternate;
  animation: mover .7s infinite alternate
}


.aboutRow2 .col-md-9 {
  padding-left: 80px;
}


.form .form-group {
  margin-bottom: 25px !important;
}

.contactPage .left {
  padding: 0px 75px;
}

.contactPage .form .form-group {
  margin-bottom: 27px !important;
}

.textarea {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  outline: none !important;
  --Textarea-focusedHighlight: none !important;
}

.textarea * {
  outline: none !important;
  border: 0px !important;
  color: #fff !important;
}

.contactPage .right .card {
  background: #242424 !important;
  height: auto;
  padding: 25px;
  border-radius: 15px;
  border-top: 2px solid #47b9d5 !important;
}

.contactPage .right .card .icon {
  width: 45px;
  height: 45px;
  background-image: linear-gradient(to right, #47b9d5, rgb(169, 60, 39));
  border-radius: 10px;
  margin-right: 20px;
}

.contactPage .right .card .icon svg {
  font-size: 25px !important;
  color: #fff !important;
}

.contactPage .right .card .col-sm-6 {
  padding-bottom: 20px;
}

.contactPage .right .card .col-sm-6:last-child {
  padding-bottom: 0px !important;
}

.contactPage .right .map {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 20px;
}

.contactPage .right .map iframe {
  width: 100%;
  height: 100%;
}

.liveBanner {
  width: 700px;
  margin: auto;
  cursor: pointer;
}

.liveBanner img {
  width: 100%;
}

.liveBanner .lazy-load-image-background {
  overflow: hidden;
  border-radius: 10px;
}



/*---videoPlayer css start here---*/

.videoPlayerWrapper .MuiDialog-paper {
  border: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.videoPlayerWrapper .playerBody {
  overflow: hidden;
}

.videoPlayer {
  width: 100% !important;
  height: 99vh !important;
}

.videoplayer-container h2 {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  padding-bottom: 50px;
  color: #fff;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.0));
  width: 100%;
}

.playerBody {
  padding: 0px !important
}

.videoPlayer video {
  object-fit: cover;
}

.playerBody .close_ {
  position: absolute;
  top: 66px;
  right: 20px;
  z-index: 10000;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  position: absolute !important;
}

.qr-code {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.playerBody .close_ svg {
  color: #fff;
  font-size: 30px;
  background-color: #0d0f1e;
  border-radius: 50%;
  padding: 5px;

}

/*---videoPlayer css ends here---*/


.homeSmlBanner .lazy-load-image-background {
  cursor: pointer;
}

.topSpace {
  margin-top: 150px;
}


.sessionBox {
  width: auto;
  margin-right: 20px;
}

.sessionBox button {
  width: 100%;
  padding: 13px 20px !important;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  border: 1px solid #3c95cf;
  font-size: 17px;
  background-image: linear-gradient(to right, #47b9d5, #3b9eb6);
  border: 0px !important;
}

.sessionBox button svg {
  margin-left: auto;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #3b9eb6 !important;
}

.sessionDropdown .MuiMenu-paper {
  min-width: 180px !important;
  z-index: 10000 !important;
}

#session-menu {
  z-index: 10000 !important;
}

.sessionDropdown .MuiMenu-paper ul li {
  font-size: 16px !important;
}

.sessionDropdown .MuiMenu-paper ul li:hover {
  background: #47b9d5 !important;
}



.sessionTabs button {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 15px;
  font-weight: 400;
  padding: 12px 20px !important;
  line-height: 1.75;
}

.sessionTabs button.Mui-selected {
  background: rgba(255, 255, 255, 0.030) !important;
  color: #fff !important;
}

.css-1aquho2-MuiTabs-indicator {
  height: 3px !important;
  width: 100% !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.searchThumb {
  margin-bottom: 15px;
  width: 19%;
}
.searchThumb.view-all.col-md-2{
  flex: 0 1 calc(20% - 10px)
}
.searchThumb .item {
  margin-bottom: 10px !important;
}

.searchThumb .title {
  color: #000 !important;
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  text-align: center;

}

.searchThumb:hover .title {
  opacity: 1;
}

.searchPage .hd {
  font-size: 25px;
}


.searchPage {
  padding-top: 140px;
  margin-top: 0px;
}

.MuiMenu-root {
  z-index: 100000 !important;
}


/*-----preHomeModal css start here----------*/
.preHomeModal .MuiDialog-paper {
  padding: 0px !important;
  background: rgba(0, 0, 0, 0.5) !important;
  border: 0px !important;
  border-radius: 0px !important;
  justify-content: center;
}

.preHomeModal .close_ {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff !important;
  border-radius: 50% !important;
  min-width: 50px !important;
  width: 50px !important;
  height: 50px !important;
  background: rgba(255, 255, 255, 0.1);
}

.preHomeModal .close_:hover {
  background: rgba(255, 255, 255, 0.2);
}

.prehome_slider {
  width: 75%;
  margin: auto;
}

.prehome_slider .swiper-slide {
  padding: 0px 10px !important;
}

.prehome_slider .swiper-slide div {
  display: flex;
  height: 100%;
  width: 100%;
}


.prehome_slider .swiper-wrapper {
  display: flex !important;
}

.prehome_slider .swiper-wrapper .swiper-slide {
  display: inline-block !important;
  float: none !important;
  height: auto !important;
}

.prehome_slider .slick-slide {
  height: auto !important;
}

.prehome_image img {
  width: 100%;
}

.prehome_video {
  width: 100%;
  height: 100%;
  position: relative;
}

.prehome_video video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.prehome_video .volume_tab {
  position: absolute;
  bottom: 30px;
  left: 15px;
  z-index: 100000;
  width: 150px !important;
  height: auto !important;
}

.prehome_video .volume_tab button {
  min-width: 35px !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 100% !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

.prehome_video .volume_tab button svg {
  color: #fff !important;
  font-size: 23px !important;
}


.preHomeModalBtn {
  position: relative;
  z-index: 1000;
  top: 20px;
}

.preHomeModalBtn button {
  padding: 8px 50px !important;
  font-weight: 500;
  border-radius: 20px !important;
}

.preHomeModal .prehome_body .prehome_into img {
  width: 110px;
}

.preHomeModal .prehome_body .prehome_into h3 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
}

.preHomeModal .prehome_body .prehome_into p {
  font-size: 18px;
  font-weight: 300;
  opacity: 0.7;
  letter-spacing: 2px;
}

/*-----preHomeModal css ends here----------*/






.videoInfoPlaceHolder {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
}

.videoInfoPlaceHolder img {
  visibility: inherit !important;
}


.emptySection {
  width: 90%;
  height: auto;
  background: #dfdfdf;
  border-radius: 10px;
  padding: 80px;
  margin: auto;
  margin-top: 30px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.emptySection .rounded-circle {
  width: 120px;
  height: 120px;
  background: #47b9d5;
}

.emptySection .rounded-circle svg {
  color: rgba(255, 255, 255, 0.8);
  font-size: 80px;
}



.cropImageModal .MuiDialog-paper {
  width: 70%;
  height: auto;
  border-radius: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background-image: linear-gradient(to right, #212121, #383838);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.cropImageModal .cropArea {
  width: 40%;
  padding: 25px;
}

.cropImageModal .canvasView {
  width: 40%;
  padding: 35px;
}


.emptySection p {
  font-weight: 300;
}


.dataView {
  display: grid;
  grid-template-rows: repeat(20, auto);
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: 320px;
  padding-top: 130px;
}

.dataView .searchThumb {
  width: 100% !important;
}



.videoPlayerPreview {
  animation: fadeOut 0.1s;
  -webkit-animation: fadeOut 0.1s;
  -moz-animation: fadeOut 0.1s;
  -o-animation: fadeOut 0.1s;
  -ms-animation: fadeOut 0.1s;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.MuiTooltip-popper {
  z-index: 1000000 !important;
}

.progressbar {
  height: 6px !important;
  background-image: linear-gradient(#47b9d5, #047e9d) !important;
}


.staticpage {
  width: 100%;
  height: auto;
  padding: 30px;
  background: #fff;
}

.staticpage h2 {
  font-size: 24px;
  font-weight: 600;
}

.staticpage h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.staticpage p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.staticpage ul {
  margin-bottom: 35px;
}

.staticpage ul li {
  list-style-type: decimal;
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 16px;
}

.staticpage ul li a {
  font-weight: 600;
}


.staticpage ol {
  margin-bottom: 35px;
}

.staticpage ol li {
  list-style-type: disc;
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 16px;
}

.staticpage ol li a {
  font-weight: 600;
}




.faqPage .MuiPaper-root {
  background: transparent !important;
  box-shadow: none !important;
  margin: 10px 0px !important;
}

.faqPage .MuiButtonBase-root {
  background: #101437 !important;
  color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 10px !important;
}

.faqPage .MuiButtonBase-root:hover {
  background: #171616 !important;
}

.faqPage .MuiButtonBase-root.Mui-expanded {
  min-height: 70px !important;
  background: #47b9d5 !important;
  color: rgba(255, 255, 255, 1) !important;
}

.faqPage .MuiButtonBase-root p {
  width: 80%;
  font-size: 18px !important;
}

.faqPage .MuiAccordionSummary-expandIconWrapper {
  color: #fff !important;
}

.faqPage .MuiAccordionSummary-content {
  margin: 20px 0px !important;
}

.faqPage .MuiAccordionDetails-root {
  padding: 25px 20px !important;
}

.faqPage .MuiAccordionDetails-root p {
  width: 80% !important;
  font-size: 15px !important;
  opacity: 1 !important;
  margin-bottom: 15px !important;
  color: #000 !important;
}


body.dark .faqPage .MuiAccordionDetails-root p {
  color: #fff !important;
  opacity: 0.8 !important;
}




/* device restriction css code start*/
.connectedDevicesPopover {
  width: 50%;
  height: auto;
  margin: 15px auto;
  z-index: 100;
  background: none;
  border-radius: 7px;
  overflow: hidden;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.connectedDevicesPopover * {
  color: #000 !important;
}

.connectedDevicesPopover .head {
  padding: 25px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.connectedDevicesPopover .head h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
}

.connectedDevicesPopover .head p {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.7;
  margin-bottom: 0px;
}

.connectedDevicesPopover .head h3 {
  color: #e39a34 !important;
  font-weight: 400;
  font-size: 22px;
}

.text-y {
  color: #e39a34;
}


.connectedDevicesPopover .scroll {
  max-height: 266px;
  overflow: scroll;
  overflow-x: hidden;
}

.connectedDevicesPopover .scroll::-webkit-scrollbar {
  background: none !important;
  width: 8px !important;
}

.connectedDevicesPopover .scroll::-webkit-scrollbar-track {
  background: none !important;
}

.connectedDevicesPopover .scroll::-webkit-scrollbar-thumb {
  background: #4f4f4f !important;
}

.connectedDevicesPopover .data {
  padding: 12px 15px;
  border: 0px !important;
}

.connectedDevicesPopover .data:nth-child(even) {
  background: #201f1f !important;
}

.connectedDevicesPopover .data:hover {
  background: #fff;
}

.connectedDevicesPopover .data h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
}

.connectedDevicesPopover .data p {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.7;
  margin-bottom: 0px;
}


.connectedDevicesPopover .data {
  border-bottom: 1px solid #e7e7e7;
}

.connectedDevicesPopover .data:last-child {
  border-bottom: 0px;
}

.connectedDevicesPopover .data .w-75 {
  padding-top: 0px;
}

.connectedDevicesPopover .data .w-75 h3 {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  margin-bottom: 5px;
}

.connectedDevicesPopover .data .w-75 span {
  font-size: 18px;
  font-weight: 500;
  color: #6e6e6e;
  opacity: 0.8;
}

.connectedDevicesPopover .data button {
  font-weight: 500 !important;
  font-size: 14px;
  text-transform: capitalize;
  padding: 3px 17px !important;
  border-radius: 10px !important;
}

.connectedDevicesPopover h4 {
  font-size: 14px;
}

.connectedDevicesPopover .close_ {
  min-width: 35px !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  position: absolute;
  top: 33px;
  right: 15px;
  z-index: 100;
  border: none !important;
}

.connectedDevicesPopover .close_ svg {
  font-size: 25px !important;
  color: #e39a34;
  transition: all 0.2s ease-in-out;
}

.connectedDevicesPopover .close_:hover svg {
  color: #000;
}

/*  */
@media (min-width:240px) and (max-width: 575px) {

  .connectedDevicesPopover .head,
  .connectedDevicesPopover .data {
    padding: 20px 25px !important;
  }

  .connectedDevicesPopover .w-75 {
    padding-bottom: 20px;
    width: 100% !important;
  }

  .connectedDevicesPopover .w-75 h3 {
    font-size: 20px;
  }

  .connectedDevicesPopover.w-75,
  .connectedDevicesPopover .w-25 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .connectedDevicesPopover .data {
    flex-direction: column;
  }
}

.connectedDevicesPopover .thisdevice {
  color: #49b4d8 !important;
  font-weight: 800 !important;
}

video.video-stream.html5-main-video {
  display: none
}

.contactBox {
  padding: 45px;
  width: 50%;
  margin: auto;
  border-radius: 10px !important;
  border: 0px !important;
  text-align: center;
  margin-top: 6%;
  border-top: 5px solid #47b9d5 !important;
}

body.dark .contactBox {
  background-image: linear-gradient(#182738, #182738);
}

body.dark .contactBox h3 {
  color: #fff !important;
}

.contactBox p {
  font-size: 25px;
  line-height: 35px
}

.spinner-loading {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
}



.fixedFooterNav {
  display: none
}

.spinerLoader {
  width: 50px !important;
  height: 50px !important;
}

body.dark .spinerLoader {
  color: rgba(255, 255, 255, 0.5) !important;
}

.footerSearch {
  display: none !important
}


.radioBanner {
  cursor: pointer;
  position: relative;
  z-index: 10000;
}

body.light .radioBanner .box {
  background: #bbbbbb;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

body.light .radioBanner .box h6,
body.light .radioBanner .box p {
  color: #000 !important;
  opacity: 1 !important;
}

.radioBanner .box {
  width: 750px;
  height: auto;
  margin: auto;
  background: #182738;
  border-radius: 15px;
  padding: 10px;
}

.radioBanner .box .imgWrapper {
  width: 100px;
  background: #fff;
  padding: 8px;
  overflow: hidden;
  border-radius: 5px;
}

.radioBanner .box .imgWrapper img {
  width: 100%;
}

.radioBanner .box .info {
  width: 70%;
}

.radioBanner .box .info h6 {
  font-size: 20px;
  margin-bottom: 0px;
}

.radioBanner .box .info p {
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 0px;
}

.radioBanner .box button {
  padding: 2px 25px !important;
  height: 30px;
  width: 70px;
}

.radioBanner .box button .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
}

.radioBanner .box .info .live-txt {
  color: red;
  font-size: 16px;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.radioBanner .box .info .live-txt span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 7px;
}


.flashing {
  width: 80px;
  height: 80px;
  border: 1px solid transparent;
  border-radius: 12px;
  background: transparent;
  cursor: default;


  margin: auto;
  z-index: 1;
}

.flashing:before {
  animation: flashing 1s 1s ease-out;
  border: 3px solid red;
}

.flashing:after {
  animation: flashing 1s .5s ease-in;
  border: 3px solid red;
}

.flashing:before,
.flashing:after {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  background: red;
  opacity: 0;
  animation-iteration-count: infinite;
  position: absolute;
  left: -30px;
  top: -3px;
  right: 0;
  bottom: 0;
  margin: auto;
}

@keyframes flashing {
  0% {
    -webkit-transform: scale(.1, .1);
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0
  }
}


.audioWrapper {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  margin-top: 15px;
  background: #242428 !important;
  border-radius: 10px;
}

.radiopPlayer.skeletonLoader {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  opacity: 1;
  padding-bottom: 17px;
  height: auto !important;
}

.radiopPlayer.skeletonLoader .audioWrapper {
  background-color: #242428 !important;
  padding: 0px !important;
  overflow: hidden;
}

.audioWrapper .box1 {
  width: 100%;
  height: 110px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  transform: scale(1) !important;
  padding: 15px !important;
}


.audioWrapper .box2 {
  width: 100px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: scale(1) !important;
  visibility: inherit !important;
}

.audioWrapper .box3 {
  width: 100px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: scale(1) !important;
  visibility: inherit !important;
  margin-left: 20px;
}


.box4Wrapper {
  width: 64%;
  position: relative;
  visibility: inherit !important;
}

.audioWrapper .box4Wrapper .circle {
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  margin: auto;
}

.audioWrapper .box4Wrapper .box4 {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: scale(1) !important;
  visibility: inherit !important;
  margin-left: 20px;
  margin-top: 10px;
}

body.light .audioWrapper {
  background: #bbbbbb !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

body.light .rhap_container {
  background: #bbbbbb !important;
  box-shadow: none !important;
}

.audioWrapper .info {
  width: 30%;
}

.audioWrapper .info .img-wrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
}

.audioWrapper .info .des h6 {
  margin-bottom: 3px;
}

body.light .audioWrapper .info .des h6,
body.light .audioWrapper .info .des p,
body.light .rhap_time {
  color: #000 !important;
  opacity: 1 !important;
}

.audioWrapper .info .des p {
  font-size: 14px;
  opacity: 0.8;
  color: #fff;
  margin-bottom: 0px;
}

body.light .rhap_download-progress {
  background: #5d5d5d !important;
}

body.light .rhap_main-controls-button,
.rhap_volume-button {
  color: #242424 !important;
}

body.light .rhap_play-pause-button {
  color: #25B5D8 !important;
}

.audioWrapper .audio {
  width: 70%;
}

.audioWrapper .audio.full {
  width: 100%;
}

body.light .audioWrapper .info .des span {
  font-weight: bold !important;
  color: #313131 !important;
}

body.light .rhap_main-controls-button,
body.light .rhap_volume-button {
  color: #313131 !important;
}



body.light .rhap_volume-bar {
  background: #313131 !important;
}

.rhap_volume-indicator {
  background: #25B5D8 !important;
}

.rhap_container {
  background: #242428 !important;
  box-shadow: none !important;
  padding: 15px !important;
}

.rhap_time {
  color: #fff !important;
}

.rhap_main-controls-button,
.rhap_volume-button {
  color: #fff !important;
}

.rhap_progress-indicator,
.rhap_progress-filled {
  background: #25B5D8 !important;
}

.rhap_controls-section .rhap_additional-controls {
  opacity: 0 !important;
  visibility: hidden !important;
}

.rhap_play-pause-button {
  color: #25B5D8 !important;
}




.radiopPlayer {
  width: 100%;
  height: auto;
  padding: 130px 65px;
  padding-bottom: 50px;
  opacity: 0;
}

.radiopPlayer.openRadioPlayer {
  opacity: 1 !important;
}

.radiopPlayer .rhap_container,
.radiopPlayer .audioWrapper {
  background: transparent !important;
}

.radiopPlayer .rhap_container {
  padding: 0px !important;
  padding-top: 5px !important;
}

.radiopPlayer .audioWrapper {
  padding: 0px !important;
}

.radiopPlayer.full .audioWrapper {
  padding: 2vh 0px !important;
  position: relative;
  top: 10px;
  padding-bottom: 0px !important;
}

.radiopPlayer.full .back {
  display: flex;
  align-items: center;
  justify-content: baseline;
  color: #fff;
  border-radius: 100% !important;
  min-width: 50px !important;
  height: 50px !important;
  width: 50px !important;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 30%;
  left: 5%;
}

.radiopPlayer.full .back svg {
  font-size: 25px;
}

body.light .radiopPlayer.full .back svg {
  color: #000 !important;
  opacity: 0.7;
}

.radiopPlayer .audioWrapper .info .img-wrapper {
  width: 210px;
  height: auto;
  margin-bottom: 15px;
  background: #fff;
}

.radiopPlayer .audioWrapper .info * {
  text-align: center;
}

.radiopPlayer .audioWrapper .info h6 {
  font-size: 25px;
  margin-bottom: 15px;
}

.radiopPlayer .rhap_main .rhap_progress-section {
  order: 2;
}

.radiopPlayer .rhap_main .rhap_controls-section {
  order: 1;
  padding-bottom: 10px !important;
}

.radiopPlayer .rhap_play-pause-button {
  font-size: 50px !important;
  width: 50px !important;
  height: 50px !important;
}

body.light .radiopPlayer .audioWrapper,
body.light .radiopPlayer .rhap_container {
  background: #bbbbbb !important;
  box-shadow: none !important;
}

body.light .rhap_progress-bar-show-download {
  background: #5d5d5d !important
}

body.light .radiopPlayer.full .audioWrapper {
  background: none !important;
}

body.light .radiopPlayer .rhap_container {
  border-radius: 15px !important;
}


.radiopPlayer.full .audioWrapper {
  padding: 70px 20px;
}

.radiopPlayer.minimize {
  height: auto !important;
  padding: 20px 65px !important;
  position: fixed;
  bottom: 3px;
  left: 0px;
  z-index: 10000;
}

.radiopPlayer.minimize .audioWrapper {
  flex-direction: row !important;
  background: #242428 !important;
  padding: 10px 15px !important;
}

.radiopPlayer.minimize .audioWrapper .info {
  flex-direction: row !important;
}

.radiopPlayer.minimize .audioWrapper .info .img-wrapper {
  width: 80px !important;
  height: auto !important;
  overflow: hidden;
  margin-bottom: 0px !important;
  padding: 10px;
  background: #fff;
}

.radiopPlayer.minimize .audioWrapper .info h6 {
  font-size: 1rem !important;
}

.radiopPlayer.minimize .audioWrapper .info * {
  text-align: left !important;
}

.radiopPlayer.minimize .audioWrapper .info .actions {
  display: none !important;
}

.fullRadioActions {
  position: absolute;
  bottom: 47px;
  right: 124px;
}

.radioView .skeleton {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 65px;
}

.radioView .skeleton .box1 {
  width: 200px !important;
  height: 260px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 10px !important;
  position: relative;
  top: 5%;
}

.radioView .skeleton .box2 {
  width: 100% !important;
  height: 118px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 10px !important;
  position: relative;
  top: 19%;
}

.radioView {
  width: 100%;
  height: auto;
}

.radioView .loader {
  position: absolute;
  top: 48%;
  left: 45%;
  z-index: 10;
  background: none !important;
  opacity: 0.7;
}

.radiopPlayer.full {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  height: 100%;
  padding: 100px 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.light .radiopPlayer.full {
  background: #f1f1f1 !important;
}

body.radioOpen {
  padding-bottom: 150px !important;
}

.radiopPlayer .actions {
  padding-left: 30px;
}

.radiopPlayer .actions button {
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  padding: 0px !important;
}

.radiopPlayer .actions button svg {
  font-size: 22px;
}

body.light .radiopPlayer .actions button svg {
  color: #000 !important;
  opacity: 0.8;
}

.radiopPlayer.full .rhap_main {
  background: rgba(255, 255, 255, 0.050);
  padding: 15px;
  border-radius: 15px !important;
}



.tags li {
  margin-bottom: 10px;
}
.serach-icon-link.active svg {
  color: #25B5D8;
}

.tags-lg li button.active {
  background: #25B5D8 !important;
  color: #fff !important;
}

.tags li button {
  color: rgba(0, 0, 0, 0.8);
  background: #cccccc;
  border-radius: 30px !important;
  padding: 5px 20px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.tags li button:hover {
  background: #25B5D8 !important;
  color: #fff !important;
}

.tags-lg li button {
  font-size: 16px !important;
  padding: 5px 25px !important;
  font-weight: 600 !important;
}

.tags-lg li button:hover {
  background: #25B5D8 !important;
  color: #fff !important;
}

.programe-schedule-banner {
  width: 100%;
  height: 300px;
  /* background: url(../images/programe-schedule.jpg) no-repeat top center; */
  /* margin-top: 100px; */
  background-size: cover !important;
  position: relative;
}

.programe-schedule-banner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(18, 21, 44, 0.7);
}

.programe-schedule-banner .container {
  position: relative;
  z-index: 100;
}

.programe-schedule-banner h1 {
  font-size: 55px;
  font-weight: bolder !important;
  margin-bottom: 0px;
  font-family: "Khand", sans-serif !important;
  letter-spacing: 2px;
}

.programe-schedule-banner h1 span {
  font-weight: bolder !important;
}

.programe-schedule {
  padding: 25px 0px 0px 0px;
}

.schedule-wrapper {
  padding: 10px 65px;
  width: 1100px;
  margin: auto;
}

.schedule-wrapper .schedule-row {
  width: 100%;
  background: #182738;
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.2s;
  border: 1px solid transparent;
  transition: all 0.2s;
  cursor: pointer;
  justify-content: space-between;
  white-space: pre;
}

body.light .schedule-wrapper .schedule-row {
  background: #e1e1e1;
}

body.light .schedule-wrapper .schedule-row * {
  color: rgba(0, 0, 0, 0.9) !important;
}

.schedule-wrapper .schedule-row:hover {
  background: rgb(20, 33, 47);
  border: 1px solid #25B5D8;
}

body.light .schedule-wrapper .schedule-row:hover {
  background: #cccccc;
  border: 1px solid rgb(132, 132, 132);
}

.schedule-wrapper .schedule-row .time {
  color: #fff;
  padding-right: 30px;
  /* width: 25%; */
}

.schedule-wrapper .schedule-row .ml-auto {
  /* width: 20%; */
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.schedule-wrapper .schedule-row .ml-auto h6 {
  color: #fff;
  margin-bottom: 0px;
}

.schedule-wrapper .schedule-row .ml-auto span {
  color: #fff;
  opacity: 0.7;
}

.schedule-wrapper .schedule-row .line {
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0.1;
}

.programe-schedule-slider {
  padding: 20px 65px;
}

.programe-schedule-slider .thumb {
  background: rgb(20, 33, 47);
  overflow: hidden;
  border-radius: 10px;
}

body.light .programe-schedule-slider .thumb {
  background: #e1e1e1 !important;
}

body.light .programe-schedule-slider .thumb * {
  color: rgba(0, 0, 0, 0.9);
}

.programe-schedule-slider .thumb .img {
  width: 20%;
  overflow: hidden;
  border-radius: 10px;
}

.programe-schedule-slider .thumb .info h4 {
  margin-bottom: 0px
}

.programe-schedule-slider:hover .sliderArrows {
  opacity: 1 !important;
}

.programe-schedule-slider .sliderArrows {
  opacity: 1 !important;
  top: 39% !important;
}

.programe-schedule-slider .swiper-button-prev {
  left: 3.5% !important;
}

.programe-schedule-slider .swiper-button-next {
  right: 3.5% !important;
}

.timeZone .selectDrop .MuiFormControl-root {
  min-width: 400px !important;
}

.timeZone .selectDrop .MuiFormLabel-root {
  color: #fff !important;
}

.timeZone .selectDrop .MuiInputBase-root {
  color: #fff !important;
}

.timeZone .selectDrop fieldset {
  border: 1px solid #fff !important;
}

.text-right {
  text-align: right;
}

.MuiMenu-paper ul li span {
  font-weight: 400 !important;
}

.nodata {
  margin: 60px 0px !important;
}

.activecat {
  text-decoration: none !important;
  background-color: #39a1bb !important;
  color: #fff !important;
}

.livepulse {
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: red;
  width: 10px;
  height: 10px;
  margin-left: 9px;
  margin-right: 7px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.livepulse:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: live 2s ease-in-out infinite;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}

@-webkit-keyframes live {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}








/*--speed dial--*/
@keyframes pop-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pop-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.speed-dial {
  text-align: center;
  display: inline-block;
  position: relative;
}

.speed-dial a,
.speed-dial a:hover,
.speed-dial a:active,
.speed-dial a:visited,
.speed-dial a:focus {
  color: #fafafa;
  display: inline-block;
  text-decoration: none;
}

.speed-dial .toggle {
  display: inline-block;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  font-size: 32px;
  line-height: 75px;
  margin: 8px 0;
}

/* .speed-dial:hover .dials button {
  animation: pop-in 0.3s ease both;
} */

.speed-dial.toggle .dials button {
  animation: pop-in 0.3s ease both;
}

.speed-dial .dials {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 100%;
  left: 5px;
  visibility: hidden;
  padding-bottom: 35px;
}

/* .speed-dial:hover .dials {
  visibility: inherit;
} */

.speed-dial.toggle .dials {
  visibility: inherit;
}


.speed-dial .dials button {
  display: inline-block;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  transform: scale(0);
  animation: pop-out 0.3s ease both;
  background: #39a1bb !important;

  transition: all 0.3s ease-in-out;
}

.speed-dial .dials button:hover {
  background: #47b9d5 !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.speed-dial .dials button svg {
  font-size: 25px !important;
  font-weight: bold !important;
  color: #fff !important;
}

.speed-dial .dials button span {
  margin: auto !important;
}

.speed-dial .dials button.pop-in {
  animation: pop-in 0.3s ease both;
}

.speed-dial .dials li:nth-child(0) button {
  animation-delay: 0.6s;
}

.speed-dial .dials li:nth-child(1) button {
  animation-delay: 0.4s;
}

.speed-dial .dials li:nth-child(2) button {
  animation-delay: 0.4s;
}

.speed-dial .dials li:nth-child(3) button {
  animation-delay: 0.3s;
}

.speed-dial .dials li:nth-child(4) button {
  animation-delay: 0.2s;
}

.speed-dial .dials li:nth-child(5) button {
  animation-delay: 0.1s;
}

.speed-dial .dials li:nth-child(6) button {
  animation-delay: 0.0s;
}




.audioWrapper {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  margin-top: 15px;
  background: #242428 !important;
  border-radius: 10px;
}

body.light .audioWrapper {
  background: #bbbbbb !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

body.light .rhap_container {
  background: #bbbbbb !important;
  box-shadow: none !important;
}

.audioWrapper .info {
  width: 30%;
}

.audioWrapper .info .img-wrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
}

.audioWrapper .info .des h6 {
  margin-bottom: 3px;
}

body.light .audioWrapper .info .des h6,
body.light .audioWrapper .info .des p,
body.light .rhap_time {
  color: #000 !important;
  opacity: 1 !important;
}

.audioWrapper .info .des p {
  font-size: 14px;
  opacity: 0.8;
  color: #fff;
  margin-bottom: 0px;
}

body.light .rhap_download-progress {
  background: #5d5d5d !important;
}

body.light .rhap_main-controls-button,
.rhap_volume-button {
  color: #242424 !important;
}

body.light .rhap_play-pause-button {
  color: #25B5D8 !important;
}

.audioWrapper .audio {
  width: 70%;
}

body.light .audioWrapper .info .des span {
  font-weight: bold !important;
  color: #313131 !important;
}

body.light .rhap_main-controls-button,
body.light .rhap_volume-button {
  color: #313131 !important;
}

body.light .rhap_volume-bar {
  background: #313131 !important;
}

body.light .rhap_volume-indicator {
  background: #25B5D8 !important;
}

.rhap_container {
  background: #242428 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  padding: 15px !important;
}

.rhap_time {
  color: #fff !important;
}

.rhap_main-controls-button,
.rhap_volume-button {
  color: #fff !important;
}

.rhap_progress-indicator,
.rhap_progress-filled {
  background: #25B5D8 !important;
}

.rhap_controls-section .rhap_additional-controls {
  opacity: 0 !important;
  visibility: hidden !important;
}

.rhap_play-pause-button {
  color: #25B5D8 !important;
}


.radiopPlayer {
  width: 100%;
  height: 80vh;
  padding: 100px 65px;
}

.radiopPlayer .rhap_container,
.radiopPlayer .audioWrapper {
  background: transparent !important;
}

.prayer-req-form .rounded-circle {
  width: 110px;
  margin: auto;
}

.prayer-req-form .rounded-circle img {
  width: 100%;
}

.prayer-req-form form {
  width: 70%;
  margin: auto;
}

.prayer-req-form form .form-group {
  width: 100%;
  margin-bottom: 20px;
}

.prayer-req-form form .form-group input {
  color: #fff !important;
}

.prayer-req-form .top-circle h3 {
  font-family: bilbo;
  margin-top: 0px;
}

body.light .prayer-req-form {
  background-image: linear-gradient(#fff, #ccc);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}


.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #47b9d5 !important;
}

.css-1yjjitx-MuiSwitch-track {
  background: #fff !important;
}

.visible {
  visibility: visible !important;
}


.noData {
  max-width: 600px;
  height: auto;
  padding: 200px 40px;
  text-align: center;
  margin: auto
}

.noData h3 {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 35px;
  font-weight: 300;
}

#pdf-container {
  width: 100%;
  height: 100vh;
  /* Adjust the height as needed */

}

/* Style the iframe to fill its container */
#pdf-iframe {
  width: 100%;
  height: 100vh;
  border: none;
  /* Remove border from iframe */
}

body.light .radiopPlayer .audioWrapper,
body.light .radiopPlayer .rhap_container {
  background: none !important;
  box-shadow: none !important;
}

body.light .rhap_progress-bar-show-download {
  background: #5d5d5d !important
}


.publicationCard .des {
  position: absolute !important;
  background: #182838 !important;
  height: 200px !important;
}

.publicationCard .des .col:first-child {
  padding-right: 5px !important;
}

.publicationCard .des .col:last-child {
  padding-left: 5px !important;
}

.publicationCard .des button {
  width: 100% !important;
  padding: 3px 15px !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
}

.rssPage {
  padding-top: 70px;
}

.rssCard .MuiSkeleton-rectangular {
  padding: 8% 0px !important;
}

.rssCard {
  max-width: 20% !important;
  flex: 0 0 20% !important;
}

.rssCard .placeholder {
  height: auto !important;
}

.rssCard .imgWrapper {
  height: 24vh !important;
}

.rssCard .des {
  height: 210px;
}

.rssCard .des p {
  margin-bottom: 5px;
}

.rssCard {
  max-width: 20% !important;
  flex: 0 0 20% !important;
}

.rssCard .placeholder {
  height: auto !important;
}

.rssCard .imgWrapper {
  height: 24vh !important;
}

.rssCard .des {
  height: 170px;
}

.rssCard .des p {
  margin-bottom: 5px;
}

.swiper-slide {
  transition: all 0.3s !important;
}

/* .publicationCard_:hover {
  transform: scale(1.1) !important;
} */

.rssCard .item:hover {
  transform: scale(1.1) !important;
}

.sliderWrapper {
  position: relative;
}

.sliderWrapper .subscribeBtn {
  position: absolute;
  bottom: 20%;
  left: 10%;
  z-index: 100;
  font-size: 18px !important;
  padding: 5px 25px !important;
}

.timeZone {
  position: relative;
  left: 25px;
}

.thankyouimage img {
  width: 300px;
  height: 300px
}

.notification {
  z-index: 2000000 !important;
  border: 2px solid #fff;
  cursor: pointer;
  background: #182738;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  align-items: center;
}

.notification-close {
  padding: 5px;
  float: right;
}

.notification-image img {
  max-width: 100%;
  height: auto;
}

.verticalspacing {
  padding: 25px 0px !important;
}

.notification-image .imgWrapper {
  width: 100%;
  height: 176px;
  background: url(../images/skeleton.jpg) no-repeat top center !important;
  margin-bottom: 15px !important;
  background-size: cover !important;
}

.notification-content {
  padding: 20px;
}

.notification-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}

.notification-message {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;

  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;

  height: 75px;

  color: #fff;
}

.notification-link {
  cursor: pointer;
  display: inline-block;
  background-image: linear-gradient(to right, #47b9d5, #39a1bb) !important;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.notification-link:hover {
  background-color: #0056b3;
}

.css-1ji7omv-MuiBackdrop-root {
  background-color: transparent !important;
}



.accordion {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
}

.accordion-item {
  color: #fff;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  background: transparent !important;
  border: 0px !important;
}

.accordion-item-header {
  background-color: #101437 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}


.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 17px;
}

.accordion-item-header {
  color: #47b9d5 !important;
}

/* .accordion-item-header::after {
  content: "\002B";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}

.accordion-item-header.active::after {
  content: "\2212";
} */

.accordion-item-body {
  height: auto;
  overflow: hidden;
  transition: all 0.2s ease-out;
  background-color: #101437 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}


.skeleton {
  display: flex;
  gap: 15px;
}

.skeleton .item {
  width: 211.143px;
}

.staticData {
  padding: 0px 13px !important;
  display: block;
}
.slick-slide{
  height: calc(50vw) !important;
  max-height: 768px;
}
.hero-slider.slick-slide img{
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.slick-slide img{
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
.common-card.itemBox.h{
  width: 320px;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent !important;
  height: auto;
  aspect-ratio: 16 / 9;
}

.itemBox.v {
  width: 360px;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent !important;
  height: auto;
  aspect-ratio: 5/7;
}
.publicationCard_v .publicationCard.h{
  width: 360px;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent !important;
  height: auto;
  aspect-ratio: 5/7;
}
.serach-icon-link.active svg{
 color: #47b9d5;
}

@import "template2Media.css";