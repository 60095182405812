@media only screen and (min-width:320px) and (max-width:1200px) {

  .res-hide {
    display: none !important;
  }

  div.res-hide {
    display: none !important;
  }

  main {
    overflow: hidden !important;
  }

  .btn-m {
    padding: 10px 25px !important;
  }



  .navOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  }

  header .header_Tabs li a::before,
  header .header_Tabs li a::after {
    display: none !important;
  }

  .videoplayerwrapper video {
    object-fit: contain !important;
  }

  .fullscreen .videoplayerwrapper video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .videoInfo h2 {
    font-weight: 500 !important;
  }

  .preHomeModal .MuiDialog-paper {
    justify-content: flex-start !important;
  }

  .prehome_slider {
    width: 100% !important;
  }

  .preHomeModal div::-webkit-scrollbar {
    display: none !important;
  }

  .prehome_slider .swiper {
    overflow: inherit !important;
  }

  .prehome_slider .swiper::-webkit-scrollbar {
    display: none !important;
    height: 0px !important;
    width: 0px !important;
  }

  .preHomeModal .slick-track {
    display: flex !important;
  }

  .preHomeModal .slick-track .slick-slide {
    display: inline-block !important;
    float: none !important;
    height: auto !important;
  }

  .prehome_slider .slick-slide {
    height: auto !important;
  }

  .dropdown_menu {
    background-image: linear-gradient(to right, #f1f1f1, #fff) !important;
    box-shadow: none !important;
  }

  body.dark .dropdown_menu {
    background-image: linear-gradient(to right, #182738, #22354c) !important;
  }

  body.dark .dropdown_menu button {
    color: rgb(255, 255, 255, 0.8) !important;
  }
  body.dark .dropdown_menu button:hover{
    color: #000 !important; 
  }
  .dropdown_menu .info {
    padding: 5px 20px !important;
  }

  .dropdown_menu button.MuiButtonBase-root {
    padding: 10px 22px !important;
  }

  .thumb_slider_section .swiper-wrapper,
  .thumb_slider_section .data {
    white-space: nowrap !important;
    overflow: scroll;
    display: block !important;
    width: 100% !important;
    transform: inherit !important;
  }

  .thumb_slider_section .swiper-wrapper::-webkit-scrollbar,
  .thumb_slider_section .data::-webkit-scrollbar {
    display: none !important;
  }

  .thumb_slider_section .swiper-wrapper .swiper-slide,
  .thumb_slider_section .data .searchThumb {
    width: 170px !important;
    float: none !important;
    display: inline-block !important;
    vertical-align: top;
    margin-right: 10px !important;
    padding: 0px !important;
  }

  .thumb_slider_section .swiper-wrapper .swiper-slide.v_thumb,
  .thumb_slider_section .swiper-wrapper .swiper-slide.h_thumb {
    width: 170px !important;
  }

  .watchNowContainer+.thumb_slider_section .searchThumb {
    width: 170px !important;
  }

  div.swiper {
    width: 100% !important;
  }

  .thumb_slider_section .data .thumb .item {
    width: 100% !important;
    transform: scale(1) !important;
  }

  .thumb_slider_section .sliderArrows,
  .thumb_slider_section .slick-arrow {
    display: none !important;
  }

  .thumb_slider_section.profile_setting {
    padding-top: 20px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .thumb_slider_section.profileForm {
    padding: 0px 0px 0px 0px !important;
  }

  .profile_setting .form {
    width: 100%;
  }

  .detailsTab.px-2 {
    padding: 0px !important;
  }

  .watchNowPagePlayerWrapper .part1 .shareSec {
    width: 300px;
  }


  footer {
    padding: 15px 0px !important;
  }

  footer .MuiGrid-root.item1 {
    display: none !important;
  }

  footer .item3 img {
    width: 100px !important;
  }



  .radiopPlayer.skeletonLoader div.radiopPlayer.full .audioWrapper.d-flex {
    background: #2a2c42 !important;
  }

  .radiopPlayer.skeletonLoader {
    padding-left: 15px !important;
    padding-right: 15px !important;
    bottom: 58px !important;
  }

  .radiopPlayer.skeletonLoader .audioWrapper {
    width: 100% !important;
  }

  .audioWrapper .box4Wrapper .box4 {
    width: 80% !important;
  }

  .timeZone {
    left: 0px !important;
  }

  .homeLoadable .placeholder {
    padding-top: 0px !important;
  }

  .rssPage {
    padding-top: 35px !important;
    margin-top: 0px !important;
  }

}

@media only screen and (min-width:320px) and (max-width:992px) {
  .dropdown_menu::before {
    right: 4px !important;
    border-bottom: 15px solid #fff !important;
  }

  .res-full {
    width: 100% !important;
  }

  .res-padding0 {
    padding: 0px !important;
  }

  .prehome_slider .swiper-scrollbar-drag {
    background: #fff !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  body header.light {
    padding: 0 !important;
  }

  header {
    background-image: none !important;
    position: sticky !important;
  }

  header .item1.part1 {
    padding-left: 25px !important;
  }


  header {
    height: auto !important;
    padding: 5px 0px !important;
  }

  header.sticky {
    height: auto !important;
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    width: 100% !important;
    padding: 0px 0px !important;
  }

  body.dark header {
    background: #182738 !important;
  }


  header .resNav {
    position: fixed !important;
    top: 0px;
    right: -100%;
    width: 65%;
    height: 100%;
    z-index: 10000;
    background: #000;
    padding: 20px 20px 20px 10px !important;
    opacity: 0;
    transition: all 0.3s;
  }

  header .resNav.open {
    right: 0px !important;
    opacity: 1;
  }

  header .resNav li {
    width: 100% !important;
    margin: 10px 0px !important;
  }

  header .resNav li a {
    display: block;
    padding: 10px 10px;
  }

  header .item1 {
    padding-left: 15px !important;
    max-width: 30%;
  }

  header .item1 img {
    width: 50px;
  }

  header .item3 {
    margin-left: auto;
    padding-right: 12px !important;
  }

  header button {
    height: auto !important;
  }


  .myAccDropdown {
    min-width: 45px !important;
  }

  .myAccDropdown {
    padding: 0px 0px !important;
  }

  .myAccDropdown .myAccDropdownTab span.rounded-circle {
    margin-right: 0px !important;
  }

  .beforeLoginMenures .info img {
    width: 70px !important;
  }


  .headerSearch.mobileSearch {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    z-index: 10000000 !important;
    background: rgba(255, 255, 255, 0.9) !important;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
  }

  .headerSearch.mobileSearch.open {
    opacity: 1 !important;
    visibility: inherit;
  }

  .headerSearch.mobileSearch .wrapper {
    background: #fff;
  }

  .headerSearch.mobileSearch .wrapper svg {
    color: #000 !important;
  }

  .headerSearch.mobileSearch svg {
    top: 23px !important;
    opacity: 0.9;
  }

  .closeSearch {
    left: 15px !important;
    opacity: 1 !important;
    color: #0000 !important;
    z-index: 100000 !important;
    pointer-events: inherit !important;
  }

  .headerSearch svg {
    color: rgba(0, 0, 0, 0.7) !important;
  }

  .headerSearch.mobileSearch input {
    height: 70px;
    padding-left: 50px !important;
    color: #000 !important;
  }

  .headerSearch.mobileSearch input::-webkit-input-placeholder {
    color: #494949 !important;
    font-weight: 500 !important;
  }


  .loginBtnWrap.loginBtnWrapRes {
    padding-right: 0px !important;
    position: relative;
    z-index: 1000;
    max-width: auto !important;
    display: flex;
    align-items: center;
  }

  .loginBtnWrap.loginBtnWrapRes .openMenuTabs {
    margin-left: 5px !important;
  }

  body.dark .openMenuTabs {
    color: #fff !important;
  }

  .dropdown-list div.MuiMenu-paper {
    border: 0px solid #3c99e4 !important;
    background: #262932 !important;
  }

  .dropdown-list .MuiMenu-paper ul li {
    margin-bottom: 10px !important;
  }

  .dropdown-list .MuiMenu-paper ul li a {
    font-size: 16px !important;
  }

  .dropdown-list .MuiMenu-paper ul li a.act {
    background: rgba(255, 255, 255, 0.040) !important;
  }

  .dropdown-list .MuiMenu-paper ul li a.act svg {
    color: #3487c6 !important;
    opacity: 1;
  }

  .dropdown-list .MuiMenu-paper ul li a svg {
    margin-right: 10px !important;
    display: none !important;
  }



  .footer_nav ul li {
    width: 100%;
    margin: 3px 0px !important;
  }

  .footer_nav ul li:after,
  .footer_nav ul li a:after {
    display: none !important;
  }

  footer .col-md-3 div.ml-auto {
    width: 100% !important;
    padding-left: 15px !important;
    margin-top: 15px;
    margin-bottom: 15px !important;
  }

  footer .item1 {
    display: none !important;
  }

  .videoInfo .container_ {
    padding: 0px 25px !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .videoInfo .container_ .wrapper {
    padding: 0px !important;
  }

  .videoInfo .container_ .wrapper p {
    font-size: 14px;
    font-weight: 300;
  }

  .videoInfo .container_ .wrapper .col h4 {
    font-size: 16px !important;
    margin-bottom: 0px;
  }

  .videoInfo .container_ .wrapper .col span {
    font-size: 16px !important;
  }

  .res-flex-col {
    flex-direction: column !important;
  }

  .res-flex-col .col,
  .res-flex-col .col-md-6 {
    width: 100% !important;
    max-width: 100% !important;
    margin: 10px 0px;
  }

  .tag {
    font-size: 12px;
    padding: 5px 10px;
  }

  .contactPage .left,
  .contactPage .right {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    padding: 0px;
  }

  .contactPage .left {
    padding: 0px 0px !important;
  }

  .contactPage .right .card .col-sm-6 {
    flex-direction: column;
  }

  .contactPage .right .card .col-sm-6 span,
  .contactPage .right .card .col-sm-6 .info {
    width: 100%;
  }

  .contactPage .right .card .col-sm-6 span.icon {
    width: 45px;
    margin-bottom: 15px;
  }

  .plansTable {
    background: none !important;
    padding: 0px !important;
  }

  .plansTable .plansTableWrapper {
    width: 100% !important;
  }

  .thumb_slider_section .plansTable .slick-track .slick-slide {
    width: 300px !important;
    padding-right: 15px !important;
    padding-left: 0px !important;
  }

  .thumb_slider_section .plansTable .slick-track .slick-slide li,
  .plansTable .plansTableWrapper .column .body p {
    white-space: initial !important;
  }

  .plansTable .slick-dots {
    display: none !important;
  }

  .table-responsive::-webkit-scrollbar {
    height: 7px !important;
  }

  .table-responsive::-webkit-scrollbar-track {
    background: none !important;
  }


  .dropdown-list .MuiMenu-paper {
    position: fixed !important;
    top: 0px !important;
    left: auto !important;
    right: 0px !important;
    min-height: 100% !important;
    min-width: 65% !important;
    width: 65% !important;
    height: 100% !important;
    border-radius: 0px !important;
  }

  .dropdown-list .MuiMenu-paper ul {
    width: 100% !important;
    max-height: inherit !important;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop {
    background: rgba(0, 0, 0, 0.7) !important;
    backdrop-filter: blur(2px);
  }


  .inner_banner {
    height: 150px !important;
  }

  .home_slider .slick-dots {
    bottom: 20px !important;
    zoom: 80% !important;
  }

  .loginModal {
    z-index: 100000 !important;
  }

  .listingPage {
    margin-top: 0px !important;
  }

  .listingPage .data {
    padding-left: 20px;
  }

  .preHomeModalBtn {
    position: fixed !important;
    bottom: 10px !important;
    top: inherit !important;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 1000000;
  }

  .preHomeModalBtn.justify-content-end {
    justify-content: center !important;
  }




  .thumb_slider_section .slick-track {
    white-space: nowrap !important;
    width: 100% !important;
    overflow-x: scroll !important;
    white-space: nowrap;
    transform: inherit !important;
  }

  .thumb_slider_section .slick-track::-webkit-scrollbar {
    display: none !important;
  }

  .thumb_slider_section .slick-track .slick-slide {
    display: inline-block !important;
    float: none !important;
    width: 200px !important;
    transform: scale(1) !important;
  }


  body,
  html {
    overflow-x: hidden;
    min-height: 100vh;
  }

  .radiopPlayer.minimize {
    bottom: 58px !important;
  }

  .thumb_slider_sectionWrapper.skeleton {
    white-space: nowrap !important;
    overflow: scroll !important;
    overflow-y: hidden !important;
    display: block !important;
  }

  .thumb_slider_sectionWrapper.skeleton::-webkit-scrollbar {
    display: none !important;
  }

  .thumb_slider_sectionWrapper.skeleton .item {
    width: 100px !important;
    display: inline-block !important;
    margin-right: 10px !important;
  }
  

}


@media only screen and (min-width:320px) and (max-width:1024px) {
  header {
    background-image: none !important;
    background: #f1f1f1 !important;
    position: sticky !important;
    height: auto !important;
    padding: 0px !important;
  }

  body.dark header {
    background: #182738 !important;
  }

  header .header_Tabs li a {
    color: #000 !important;
  }

  body.dark header .header_Tabs li a {
    color: #fff !important;
    opacity: 0.8;
  }

  .searchPage {
    padding-top: 30px !important;
  }

  .headerSearch {
    left: 0px !important;
  }

  body.light .headerSearch {
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
  }

  body.dark .headerSearch {
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
  }

  .headerSearch input {
    color: rgba(0, 0, 0, 0.7) !important;
  }

  body.dark .headerSearch input {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .headerSearch input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 1) !important;
  }

  .headerSearch svg.MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.7) !important;
  }

  .headerSearch.footerSearch {
    display: flex !important
  }

  .searchPage .hd,
  .tags,
  ul.tags {
    padding-left: 0px !important;
  }
  
 div .publicationCard .des {
  bottom: -15px;
}
div .publicationCard .des h6{
  white-space: pre-line;
}
div .publicationCard_v .publicationCard.h{
  aspect-ratio: 5/8;
}
}

@media only screen and (min-width:320px) and (max-width:767px) {

  .banner .overlay * {
    display: none !important;
  }

  main {
    padding-bottom: 0px !important;
  }

  .headerSearch {
    width: 100% !important;
    height: 50px !important;
    margin-bottom: 10px !important;
  }

  .headerSearch input {
    height: 48px !important;
  }

  .headerSearch svg {
    top: 9px !important;
  }

  .tags {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .tags::-webkit-scrollbar {
    display: none !important;
  }

  .ticketSection table thead th,
  .ticketSection table td {
    padding: 5px 10px !important;
    font-size: 13px !important;
    white-space: nowrap;
  }

  .ticketSection table td .badge {
    font-size: 12px !important;
    padding: 5px 10px !important;
    font-weight: 300 !important;
  }

  .d-flex.detailsTab {
    white-space: nowrap;
    overflow-x: scroll;
    display: block !important;
    zoom: 70%;
  }

  .detailsTab .sessionBox,
  .detailsTab .sessionTabs {
    display: inline-block;
    vertical-align: top;
  }

  .detailsTab::-webkit-scrollbar {
    display: none !important;
  }


  .footer_nav {
    margin-bottom: 10px !important;
  }

  .footer_nav ul {
    text-align: center !important;
    padding-left: 15px;
  }

  .footer_nav ul li {
    width: max-content;
    padding: 0px 10px !important;
  }

  .footer_nav ul li a {
    font-size: 14px !important;
  }

  .inner_banner {
    margin-top: 70px !important;
  }


  .acc_drop {
    width: 80% !important;
    height: 100vh;
    position: fixed !important;
    top: 0px !important;
    left: inherit !important;
    right: -100% !important;
    transform: scale(1) !important;
    border-radius: 0px !important;
  }

  .acc_drop.show {
    right: 0px !important;
  }


  .prehome_slider.my-5 {
    margin-top: 10px !important;
  }

  .preHomeModal .prehome_body .prehome_into {
    width: 100% !important;
  }

  .preHomeModal .prehome_body .prehome_into img {
    width: 70px !important;
  }

  .preHomeModal .prehome_body .prehome_into h3 {
    font-size: 18px !important;
    line-height: 25px !important;
    font-weight: 300 !important;
  }

  .preHomeModal .prehome_body .prehome_into p {
    font-size: 14px !important;
    line-height: 25px !important;
    font-weight: 300 !important;
    margin-bottom: 0px !important;
  }

  .watchNowPagePlayerWrapper .part1 {
    padding: 15px 0px !important;
  }

  .watchNowPagePlayerWrapper h3 {
    font-size: 22px !important;
  }

  .watchNowPagePlayerWrapper .part1 .des {
    max-height: inherit !important;
    padding-right: 0px !important;
  }

  .watchNowPagePlayerWrapper .part1 .des p {
    font-size: 13px !important;
  }

  .watchNowPagePlayerWrapper {
    padding-bottom: 0px !important;
  }


  .item:hover {
    transform: scale(1) !important;
  }

  .profile_setting {
    padding-bottom: 30px;
  }

  .profileForm {
    margin-bottom: 25px !important;
  }

  .cropImageModal .MuiDialog-paper.MuiPaper-rounded {
    border-radius: 0px !important;
    height: 100vh !important;
    padding-top: 50px !important;
  }

  .cropImageModal .playerBody {
    max-height: 100vh;
    overflow: scroll !important;
  }

  .cropAreaWrapper {
    flex-direction: column;
  }

  .cropImageModal .MuiDialog-paper {
    width: 100% !important;
  }

  .cropArea,
  .canvasView {
    width: 100% !important;
  }

  .thumb_slider_section .title {
    font-weight: 500 !important;
  }

  .myList .containerWrapper {
    width: 100% !important;
  }

  .emptySection {
    width: 100% !important;
    padding: 30px !important;
  }

  .emptySection p {
    width: 100% !important;
  }

  .emptySection .rounded-circle {
    zoom: 70% !important;
  }

  .contactPage {
    max-width: 100% !important;
  }

  .contactPage .row {
    margin: 0px !important;
  }

  .contactPage_Wrapper .profileForm {
    padding: 20px !important;
  }

  .contactPage_Wrapper .profileForm .left {
    margin-bottom: 25px;
  }

  .contactPage_Wrapper .profileForm .left h3 {
    font-size: 20px !important;
  }

  .contactBox {
    width: 100% !important;
  }

  .contactBox p {
    font-size: 17px !important;
    line-height: 29px;
  }


  .faqPage .MuiButtonBase-root p.MuiTypography-root {
    width: 90%;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .faqPage .MuiAccordionDetails-root p.MuiTypography-root {
    width: 100% !important;
  }


  .staticData p,
  .staticData ul li {
    font-size: 15px !important;
  }

  .top-padding {
    margin-top: 20px !important;
  }

  .staticData,
  grid.staticData {
    padding: 0px 0px !important;
  }

  .accordion {
    width: 100% !important;
  }

}

@media only screen and (min-width:320px) and (max-width:575px) {
  .sliderWrapper button.subscribeBtn {
    font-size: 13px !important;
    padding: 4px 12px !important;
  }

  .homeLoadable .banner img.img {
    width: 120px !important;
  }

  .homeLoadable .placeholder {
    padding-top: 40px !important;
  }

  .listingPage .hd,
  .inner_hd h3 {
    font-size: 20px !important;
  }

  .thumb_slider_section .title {
    font-size: 17px !important;
  }

  .thumb_slider_section .title a {
    font-size: 16px !important;
  }

  .thumb_slider_section {
    padding: 10px 7px !important;
  }

  .thumb_slider_section .slick-slide {
    padding: 0px 5px !important;
  }

  .cat_slider.v_thumb .slick-slide {
    width: 140px !important;
  }

  .thumb_slider_section .swiper-wrapper .swiper-slide.v_thumb {
    width: 100px !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide.v_thumb {
    width: 170px !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide * {
    white-space: normal !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide p {
    display: none !important;
  }

  .item .des {
    height: 92px !important;
  }


  .profile_setting .profile h4 {
    font-size: 15px !important;
  }

  .profile_setting .form {
    width: 100% !important;
  }

  .inner_banner h3 {
    font-size: 25px !important;
  }

  body.dark .dropdown_menu button {
    font-size: 16px !important;
  }

  .myList.py-5 {
    padding: 0px !important;
  }

  .myListWrapper {
    overflow: inherit !important;
    max-width: 100% !important;
  }

  .myList .col-md-4 {
    padding: 0px !important;
    margin-bottom: 25px;
  }

  .myList .col-md-4 h3 {
    margin-bottom: 10px !important;
  }

  .myList .col-md-4 .info h5 {
    margin-bottom: 0px !important;
  }

  .myList .col-md-4 div.d-flex {
    flex-direction: column;
  }

  .myList .col-md-4 div.d-flex .img {
    margin-right: 0px !important;
    width: 100%;
  }

  .myList .col-md-4 div.d-flex .info {
    width: 100%;
    padding-top: 15px;
  }

  .myList .col-md-4 div.d-flex.my-3 {
    flex-direction: row;
    margin: 5px 0px !important;
  }

  .myList .col-md-4 div.d-flex .col {
    width: 100%;
  }


  .listingPage .hd {
    margin-top: 0px !important;
    font-size: 22px !important;
  }

  .listingPage .MuiGrid-grid-xs-12 {
    padding: 0px 20px !important;
  }

  .listingPage .data .item {
    width: 46% !important;
    margin-right: 10px !important;
    margin-bottom: 0px !important;
  }

  .searchThumb .title {
    font-size: 13px !important;
  }
   
  .searchThumb.col-md-2 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    padding: 0px 8px !important;
  }

  .MuiDialog-paper {
    padding: 10px !important;
  }

  .modal-body {
    padding: 10px !important;
  }


  .ticketTTable .col-md-4 .item .MuiSkeleton-rectangular {
    position: relative;
    height: 30vh !important;
  }

  .myList .col-md-4 .box_ {
    align-items: flex-start !important;
  }

  .myList .col-md-4 .item_,
  .myList .col-md-4 .info {
    max-width: 100% !important;
    padding-left: 0px !important;
    width: 100% !important;
  }


  .footer_nav {
    width: 100%;
    padding-left: 0px !important;
    margin-bottom: 15px !important;
  }

  .footerContainer {
    flex-direction: column !important;
  }

  footer .item1,
  footer .item2,
  footer .item3 {
    width: 100% !important;
    max-width: 100% !important;
  }

  footer .item3 {
    width: 100% !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    justify-content: center !important;
  }

  .preHomeModalBtn button.MuiButtonBase-root {
    padding: 6px 30px !important;
  }

  .langModalList li {
    width: 44% !important;
    flex: 0 0 44% !important;
  }


  .watchNowPagePlayerWrapper .part1 .shareSec button {
    font-size: 14px !important;
  }

  .loginModal form {
    padding: 10px !important;
  }

  .loginModal .MuiDialog-paper {
    margin: 20px !important;
  }

  .loginModal button.bg-red {
    height: 50px !important;
  }

  .watchNowPagePlayerWrapper .part2 .placeholder .img {
    width: 85px !important;
  }


  div.radiopPlayer.minimize {
    padding: 20px 10px !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .img-wrapper {
    display: none !important;
  }

  div.radiopPlayer.minimize .audioWrapper {
    flex-direction: column !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info {
    width: 100% !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info .actions {
    display: flex !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 0px !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des h6 {
    width: 100%;
  }

  .radiopPlayer .audioWrapper .info h6 {
    font-size: 18px !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des p {
    margin-left: 30px;
    display: none;
  }

  .radiopPlayer.minimize .audioWrapper .info .des span {
    margin-left: auto;
  }

  div.radiopPlayer.minimize .audioWrapper .info .des .actions {
    margin-left: auto !important;
    padding-left: 0px !important;
    justify-content: flex-end;
  }

  div.radiopPlayer.minimize .actions {
    width: 150px !important;
  }

  div.radiopPlayer.minimize .actions button {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    padding: 5px !important;
    margin: 0px !important;
  }

  div.radiopPlayer.minimize .speed-dial .dials {
    left: -15px !important;
    bottom: 60% !important;
  }

  div.radiopPlayer.minimize .speed-dial .dials button {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    padding: 5px !important;
    margin: 10px !important;
  }

  div.radiopPlayer.minimize .actions button svg {
    font-size: 30px !important;
  }

  div.radiopPlayer.minimize .actions.res-hide {
    display: none !important;
  }

  div.radiopPlayer.minimize .audioWrapper .audio.full {
    zoom: 80%;
  }

  body.dark.radioOpen,
  body.light.radioOpen {
    padding-bottom: 148px !important;
  }

  .radiopPlayer.full {
    padding: 130px 25px !important;
  }

  .radiopPlayer.full .audioWrapper .info .img-wrapper {
    width: 120px !important;
  }

  .radiopPlayer.full .audioWrapper .info {
    width: 100% !important;
  }

  .radioView {
    height: 65vh !important;
  }

  div.radiopPlayer.full .audioWrapper .audio.full {
    zoom: 80%;
  }

  .radioBanner .box {
    width: 95% !important;
    flex-direction: column;
  }

  .radioBanner .box .info div.d-flex {
    justify-content: center;
  }

  .radioBanner .box * {
    text-align: center;
  }

  .radioBanner .box p {
    margin-bottom: 15px !important;
  }

  div.radiopPlayer.full {
    height: 90% !important;
  }

  div.radiopPlayer.full .audioWrapper {
    background: none !important;
    padding: 0px !important;
    top: 0px !important;
  }

  .fullRadioActions {
    bottom: 75px !important;
    right: 0 !important;
  }

  .radiopPlayer button.rhap_play-pause-button {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }

  footer .item3 img {
    width: 70px !important;
  }

  div.container {
    padding: 0px 15px !important;
  }

  .programe-schedule .timeZone {
    width: 100% !important;
    flex-direction: column;
  }

  .programe-schedule .timeZone .info * {
    text-align: center !important;
  }

  .programe-schedule .timeZone div.selectDrop {
    padding: 0px 15px !important;
    padding-top: 20px !important;
    width: 100% !important;
  }

  .timeZone .selectDrop div.MuiFormControl-root {
    min-width: 100% !important;
  }

  .tags,
  ul.tags {
    width: 100% !important;
  }

  ul.tags-lg li button,
  .tags-lg li button {
    font-size: 14px !important;
    padding: 3px 15px !important;
  }

  .schedule-wrapper {
    padding: 10px 15px !important;
    width: 100% !important;
  }

  /* .schedule-wrapper .schedule-row {
      flex-direction: column;
    } */

  .schedule-wrapper .schedule-row * {
    width: 100% !important;
  }

  .programe-schedule .schedule-wrapper .schedule-row .time {
    padding-right: 10px !important;
  }

  .programe-schedule .schedule-wrapper .schedule-row .ml-auto {
    padding-left: 10px;
  }

  .schedule-wrapper .schedule-row .ml-auto h6 {
    white-space: pre-line;
  }

  .schedule-wrapper .schedule-row .line {
    padding: 0px !important;
  }


  .programe-schedule-slider .swiper-wrapper {
    white-space: nowrap !important;
    overflow: scroll;
    display: block !important;
    width: 100% !important;
    transform: inherit !important;
  }

  .programe-schedule-slider .swiper-wrapper::-webkit-scrollbar {
    display: none !important;
  }

  .programe-schedule-slider .thumb {
    display: inline-block !important;
    width: 230px !important;
    margin-right: 10px !important;
  }

  .programe-schedule-slider .thumb {
    flex-direction: column;
  }

  .programe-schedule-slider .thumb .img,
  .programe-schedule-slider .thumb .info {
    width: 100% !important;
  }

  .programe-schedule-slider .thumb .info {
    padding: 20px 15px !important;
  }

  .programe-schedule-slider {
    padding: 20px 20px !important;
  }

  .prayer-req-form {
    width: 100% !important;
    margin-bottom: 140px !important;
  }

  .prayer-req-form form {
    width: 100% !important;
  }

  .homeSmlBanner .css-1osj8n2-MuiGrid-root {
    max-width: 100% !important;
    flex-basis: 90% !important;
    margin-bottom: 20px !important;
  }

  .schedule-wrapper .schedule-row {
    padding: 15px 15px !important;
  }

  .radioBannerPreview {
    max-width: 90% !important;
    flex: 0 0 90% !important;
  }


  .schedule-wrapper .schedule-row .time {
    white-space: nowrap;
    width: 100% !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 100% !important;
  }


  .rssPage .searchThumb.col-md-2 {
    flex: 0 0 48% !important;
    max-width: 48% !important;
    padding: 0px 8px !important;
  }

  .rssPage .rssCard div.des {
    height: 170px !important;
  }

  .rssPage .rssCard div.des p {
    white-space: inherit !important;
  }

  .speed-dial {
    position: relative;
    top: 10px;
  }

  .radiopPlayer.minimize .speed-dial {
    position: relative;
    top: 0px !important;
  }



}



@media only screen and (min-width:430px) and (max-width:575px) {
  div.searchThumb.col-md-2 {
    flex: 0 0 33.3333333333% !important;
    max-width: 33.3333333333% !important;
    padding: 0px 8px !important;
  }
}

@media only screen and (min-width:575px) and (max-width:767px) {
  .radioBannerPreview {
    max-width: 90% !important;
    flex: 0 0 90% !important;
  }

  .footer_nav ul li {
    width: max-content;
    margin-right: 20px !important;
  }

  .thumb_slider_section .title {
    font-size: 20px !important;
  }

  .thumb_slider_section {
    padding: 10px 5px !important;
  }

  .cat_slider.v_thumb .slick-slide {
    width: 180px !important;
  }

  .thumb_slider_section .swiper-wrapper .swiper-slide.v_thumb {
    width: 120px !important;
  }


  .thumb_slider_section2 .swiper-wrapper .swiper-slide.v_thumb {
    width: 170px !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide * {
    white-space: normal !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide p {
    display: none !important;
  }

  div.swiper-wrapper div.swiper-slide.publicationCard_ {
    width: 230px !important;
  }

  .myListWrapper {
    overflow: inherit !important;
  }

  .myList .col-md-4 {
    padding: 0px !important;
    margin-bottom: 25px;
  }

  .listingPage .hd {
    margin-top: 0px !important;
    font-size: 22px !important;
  }

  .listingPage .MuiGrid-grid-xs-12 {
    padding: 0px 20px !important;
  }

  .listingPage .data .item {
    width: 30% !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }


  .searchThumb.col-md-2 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
    padding: 0px 8px !important;
  }


  .myList .col-md-4 .item {
    max-width: 100% !important;
  }

  .myList .col-md-4 .info {
    max-width: 60% !important;
  }

  .dropdown-list .MuiMenu-paper {
    width: 45% !important;
    min-width: 45% !important;
  }

  .videoInfo h2 {
    font-weight: 500 !important;
    font-size: 35px;
  }

  .footer_nav {
    width: 100%;
    padding-left: 0px !important;
    margin-bottom: 15px !important;
  }


  .thumb_slider_section .plansTable .slick-track .slick-slide {
    width: 350px !important;
  }



  div.radiopPlayer.minimize {
    padding: 20px 10px !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .img-wrapper {
    display: none !important;
  }

  div.radiopPlayer.minimize .audioWrapper {
    flex-direction: column !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info {
    width: 100% !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info .actions {
    display: flex !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 0px !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des h6 {
    width: 100%;
  }

  .radiopPlayer.minimize .audioWrapper .info .des p {
    margin-left: 30px;
    display: none;
  }

  .radiopPlayer.minimize .audioWrapper .info .des span {
    margin-left: auto;
  }

  div.radiopPlayer.minimize .audioWrapper .info .des .actions {
    margin-left: auto !important;
    justify-content: flex-end;
  }

  div.radiopPlayer.minimize .actions {
    width: 150px !important;
  }

  div.radiopPlayer.minimize .actions button {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    padding: 5px !important;
  }

  div.radiopPlayer.minimize .actions button svg {
    font-size: 30px !important;
  }

  div.radiopPlayer.minimize .actions.res-hide {
    display: none !important;
  }

  div.radiopPlayer.minimize .audioWrapper .audio.full {
    zoom: 80%;
  }

  body.dark.radioOpen,
  body.light.radioOpen {
    padding-bottom: 94px !important;
  }


  .radiopPlayer.minimize .audioWrapper .info .des h6 {
    white-space: nowrap;
  }

  div.radiopPlayer.full .audioWrapper {
    background: none !important;
    padding: 0px !important;
    top: 0px !important;
  }

  .radiopPlayer.full {
    padding: 130px 25px !important;
  }

  .radiopPlayer.full .audioWrapper .info .img-wrapper {
    width: 120px !important;
  }

  .radiopPlayer.full .audioWrapper .info {
    width: 100% !important;
  }

  .radioView {
    height: 74vh !important;
  }

  div.radiopPlayer.full .audioWrapper .audio.full {
    zoom: 80%;
  }

  .radioBanner .box {
    width: 95% !important;
  }

  div.radiopPlayer.minimize .speed-dial .dials {
    left: -10px !important;
    bottom: 42% !important;
  }

  div.radiopPlayer.minimize .speed-dial .dials button {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    padding: 5px !important;
    margin: 10px !important;
  }



  .programe-schedule .timeZone {
    width: 100% !important;
  }

  .programe-schedule .timeZone .info {
    width: 40% !important;
  }

  .programe-schedule .timeZone .selectDrop {
    width: 60% !important;
  }

  .timeZone .selectDrop div.MuiFormControl-root {
    min-width: 100% !important;
  }



  div.container {
    padding: 0px 15px !important;
  }

  .programe-schedule .timeZone {
    width: 70% !important;
  }


  .timeZone .selectDrop div.MuiFormControl-root {
    min-width: 100% !important;
  }

  .tags,
  ul.tags {
    width: 100% !important;
  }

  ul.tags-lg li button,
  .tags-lg li button {
    font-size: 14px !important;
    padding: 3px 15px !important;
  }

  .schedule-wrapper {
    padding: 10px 15px !important;
    width: 100% !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 50% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto {
    width: 37% !important;
  }



  .programe-schedule-slider .swiper-wrapper {
    white-space: nowrap !important;
    overflow: scroll;
    display: block !important;
    width: 100% !important;
    transform: inherit !important;
  }

  .programe-schedule-slider .swiper-wrapper::-webkit-scrollbar {
    display: none !important;
  }

  .programe-schedule-slider .thumb {
    display: inline-block !important;
    width: 230px !important;
    margin-right: 10px !important;
  }

  .programe-schedule-slider .thumb {
    flex-direction: column;
  }

  .programe-schedule-slider .thumb .img,
  .programe-schedule-slider .thumb .info {
    width: 100% !important;
  }

  .programe-schedule-slider .thumb .info {
    padding: 20px 15px !important;
  }

  .programe-schedule-slider {
    padding: 20px 20px !important;
  }


  .homeSmlBanner .css-1osj8n2-MuiGrid-root {
    max-width: 100% !important;
    flex-basis: 90% !important;
    margin-bottom: 20px !important;
  }

  .prayer-req-form {
    width: 100% !important;
    margin-bottom: 40px !important;
  }

  .schedule-wrapper .schedule-row {
    flex-direction: column !important;
    gap: 10px !important;
    padding: 15px !important;
  }

  .schedule-wrapper .schedule-row .time {
    white-space: nowrap;
    width: 100% !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 100% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto {
    margin-left: inherit !important;
    width: 100% !important;
    padding-left: 0px !important;
  }


  .rssPage .searchThumb.col-md-2 {
    flex: 0 0 33% !important;
    max-width: 33% !important;
    padding: 0px 8px !important;
  }

  .rssPage .rssCard div.des {
    height: 170px !important;
  }

  .rssPage .rssCard div.des p {
    white-space: inherit !important;
  }


}

@media only screen and (min-width:767px) and (max-width:992px) {

  header {
    transition: all 0.0s !important;
  }

  .ipadMenu {
    display: block !important;
  }

  .ipadMenu * {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .openMenuTabs {
    display: none !important;
  }

  .banner .overlay {
    height: 65% !important;
  }

  .banner .overlay button {
    height: 40px !important;
    font-size: 14px !important;
    padding: 10px 20px !important;
  }

  .dropdown-list .MuiMenu-paper {
    width: 40% !important;
    min-width: 40% !important;
  }

  footer .item2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .footer_nav {
    padding-left: 20px !important;
  }

  footer .MuiGrid-root.item3 {
    padding-left: 40px !important;
    padding-right: 30px !important;
  }

  footer .item3 img {
    width: auto !important;
  }

  .footer_nav ul li {
    width: max-content;
    margin-right: 20px !important;
  }

  .myListWrapper {
    overflow: inherit !important;
  }

  .myList .col-md-4 {
    padding: 0px 20px !important;
    margin-bottom: 25px;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .myList .col-md-4 div.d-flex {
    flex-direction: column;
  }

  .myList .col-md-4 div.d-flex .img {
    margin-right: 0px !important;
    width: 100%;
  }

  .myList .col-md-4 div.d-flex .info {
    width: 100%;
    padding-top: 15px;
  }

  .myList .col-md-4 div.d-flex.my-3 {
    flex-direction: row;
    margin: 5px 0px !important;
  }

  .myList .col-md-4 div.d-flex .col {
    width: 100%;
  }

  .myList .col-md-4 .box_ {
    align-items: flex-start !important;
  }

  .myList .col-md-4 .item_ {
    max-width: 100% !important;
    align-items: flex-start !important;
  }

  .myList .col-md-4 .info {
    padding-left: 0px !important;
  }

  .thumb_slider_section {
    padding: 10px 0px !important;
  }

  .thumb_slider_section .swiper-wrapper .swiper-slide.v_thumb {
    width: 120px !important;
  }


  .thumb_slider_section2 .swiper-wrapper .swiper-slide.v_thumb {
    width: 170px !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide * {
    white-space: normal !important;
  }

  .thumb_slider_section2 .swiper-wrapper .swiper-slide p {
    display: none !important;
  }

  .myListWrapper {
    overflow: inherit !important;
  }

  .myList .col-md-4 {
    margin-bottom: 25px;
  }

  .ticketSection table td {
    white-space: nowrap;
  }

  .listingPage .hd {
    margin-top: 0px !important;
  }

  .listingPage .MuiGrid-grid-xs-12 {
    padding: 0px 20px !important;
  }

  .listingPage .data .item {
    width: 22.5% !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }



  .searchThumb.col-md-2 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
    padding: 0px 8px !important;
  }


  .ticketTTable .col-md-4 .item .MuiSkeleton-rectangular {
    position: relative;
    height: 25vh !important;
  }

  .myList .col-md-4 .item,
  .myList .col-md-4 .info {
    max-width: 100% !important;
  }

  .videoInfo h2 {
    font-weight: 500 !important;
    font-size: 35px;
  }

  .footerContainer {
    display: flex !important;
  }

  .cat_slider.v_thumb .slick-slide {
    width: 170px !important;
  }



  .preHomeModal .prehome_body .prehome_into {
    width: 100% !important;
  }

  .preHomeModal .prehome_body .prehome_into img {
    width: 130px !important;
  }

  .preHomeModal .prehome_body .prehome_into h3 {
    font-size: 22px !important;
    line-height: 35px !important;
    font-weight: 300 !important;
  }

  .preHomeModal .prehome_body .prehome_into p {
    font-size: 14px !important;
    line-height: 25px !important;
    font-weight: 300 !important;
    margin-bottom: 0px !important;
  }

  .thumb_slider_section .plansTable .slick-track .slick-slide {
    width: 400px !important;
  }

  .contactPage {
    max-width: 100% !important;
  }


  .videoPlayerWrapper #playercontainer_wrapper {
    height: 100vh !important;
  }

  body.dark.radioOpen,
  body.light.radioOpen {
    padding-bottom: 75px !important;
  }

  div.radiopPlayer.minimize {
    padding: 10px 10px !important;
  }

  div.radiopPlayer.minimize .img-wrapper {
    display: none !important;
  }

  div.radiopPlayer.minimize .des .actions {
    display: block !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info .actions {
    display: flex !important;
    margin-left: auto !important;
  }

  .radiopPlayer.full {
    padding: 130px 25px !important;
  }

  .radiopPlayer.full .audioWrapper .info .img-wrapper {
    width: 120px !important;
  }

  .radiopPlayer.full .audioWrapper .info {
    width: 100% !important;
  }

  .radioView {
    height: 75vh !important;
  }

  div.radiopPlayer.full .audioWrapper .audio.full {
    zoom: 80%;
  }


  div.radiopPlayer.minimize .audioWrapper {
    flex-direction: column !important;
  }

  div.radiopPlayer.minimize .audioWrapper .info {
    width: 100% !important;
  }

  div.radiopPlayer.minimize .audioWrapper .audio.full {
    zoom: 80%;
  }

  div.radiopPlayer.minimize .actions.res-hide {
    display: none !important;
  }

  .radiopPlayer.minimize .audioWrapper .info .des {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 0px !important;
  }


  div.radiopPlayer.full .audioWrapper {
    background: none !important;
    padding: 0px !important;
    top: 0px !important;
  }


  div.container {
    padding: 0px 15px !important;
  }

  .programe-schedule .timeZone {
    width: 70% !important;
  }

  .timeZone .info {
    width: 40% !important;
  }

  .timeZone .selectDrop {
    width: 60% !important;
  }

  .timeZone .selectsDrop div.MuiFormControl-root {
    min-width: 100% !important;
  }

  .timeZone .selectDrop .MuiFormLabel-root {
    color: #fff !important;
  }

  .timeZone .selectDrop .MuiInputBase-root {
    color: #fff !important;
  }

  .timeZone .selectDrop fieldset {
    border: 1px solid #fff !important;
  }

  .tags,
  ul.tags {
    width: 100% !important;
  }

  ul.tags-lg li button,
  .tags-lg li button {
    font-size: 14px !important;
    padding: 3px 15px !important;
  }

  .schedule-wrapper {
    padding: 10px 15px !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 55% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto {
    width: 30% !important;
  }



  .programe-schedule-slider .swiper-wrapper {
    white-space: nowrap !important;
    overflow: scroll;
    display: block !important;
    width: 100% !important;
    transform: inherit !important;
  }

  .programe-schedule-slider .swiper-wrapper::-webkit-scrollbar {
    display: none !important;
  }

  .programe-schedule-slider .thumb {
    display: inline-block !important;
    width: 200px !important;
    margin-right: 10px !important;
  }

  .programe-schedule-slider .thumb {
    flex-direction: column;
  }

  .programe-schedule-slider .thumb .img,
  .programe-schedule-slider .thumb .info {
    width: 100% !important;
  }

  .programe-schedule-slider .thumb .info {
    padding: 20px 15px !important;
  }

  .programe-schedule-slider {
    padding: 20px 20px !important;
  }


  .homeSmlBanner .css-1osj8n2-MuiGrid-root {
    max-width: 70% !important;
    flex-basis: 90% !important;
    margin-bottom: 20px !important;
  }


  .schedule-wrapper .schedule-row {
    flex-direction: row !important;
    gap: 10px !important;
    padding: 15px !important;
  }

  .schedule-wrapper .schedule-row .time {
    white-space: nowrap;
    width: 30% !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 40% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto {
    width: 30% !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }




  .rssPage .searchThumb.col-md-2 {
    flex: 0 0 24% !important;
    max-width: 24% !important;
    padding: 0px 8px !important;
  }

  .rssPage .rssCard div.des {
    height: 170px !important;
  }

  .rssPage .rssCard div.des p {
    white-space: inherit !important;
  }


}



@media only screen and (min-width:992px) and (max-width:1200px) {

  div.res_nav__ {
    display: block !important;
  }

  .myList .col-md-4 {
    padding: 0px 20px !important;
    margin-bottom: 25px;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .ticketTTable .col-md-4 .item .MuiSkeleton-rectangular {
    position: relative;
    height: 120px !important;
  }

  .resNav {
    display: flex !important;
  }

  header.sticky .headerSearch {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }


  .preHomeModal .prehome_body .prehome_into {
    width: 80% !important;
  }

  .preHomeModal .prehome_body .prehome_into img {
    width: 130px !important;
  }

  .preHomeModal .prehome_body .prehome_into h3 {
    font-size: 22px !important;
    line-height: 35px !important;
    font-weight: 300 !important;
  }

  .preHomeModal .prehome_body .prehome_into p {
    font-size: 14px !important;
    line-height: 25px !important;
    font-weight: 300 !important;
    margin-bottom: 0px !important;
  }

  .contactPage {
    max-width: 100% !important;
  }

  .searchThumb.col-md-2 {
    flex: 0 0 14% !important;
    max-width: 14% !important;
    padding: 0px 8px !important;
  }


  div.radiopPlayer.full .audioWrapper {
    background: none !important;
    padding: 0px !important;
    top: 0px !important;
  }

  .homeSmlBanner .css-1osj8n2-MuiGrid-root {
    max-width: 70% !important;
    flex-basis: 90% !important;
    margin-bottom: 20px !important;
  }


  .schedule-wrapper .schedule-row {
    flex-direction: row !important;
    gap: 10px !important;
    padding: 15px !important;
  }

  .schedule-wrapper .schedule-row .time {
    white-space: nowrap;
    width: 30% !important;
  }

  .schedule-wrapper .schedule-row .line {
    width: 40% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto {
    width: 30% !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rssPage .searchThumb.col-md-2 {
    flex: 0 0 24% !important;
    max-width: 24% !important;
    padding: 0px 8px !important;
  }

  .rssPage .rssCard div.des {
    height: 170px !important;
  }

  .rssPage .rssCard div.des p {
    white-space: inherit !important;
  }


}

@media only screen and (min-width:320px) and (max-width:992px) {
  body.dark ul.shareDropdown {
    background: rgba(0, 0, 0, 0.8) !important;
  }

  .shareDropdown {
    position: fixed !important;
    bottom: -50px !important;
    top: inherit !important;
    height: 100%;
    justify-content: flex-end;
    display: flex !important;
    flex-direction: column;
    opacity: 0 !important;
    visibility: hidden !important;
    backdrop-filter: blur(2px);
    z-index: 10000000 !important;
  }

  .shareDropdown.show {
    bottom: 0px !important;
    opacity: 1 !important;
    visibility: inherit !important;
  }

  .shareDropdown li {
    padding: 12px 0px;
    background: #12152c !important;
  }

  .shareDropdown li button.MuiButton-root {
    padding: 15px 15px !important;
  }

  .watchNowPagePlayerWrapper .part1 .shareSec button {
    font-size: 16px !important;
  }

  section.thumb_slider_section:first-child {
    margin-top: 0px !important;
  }

  section.thumb_slider_section {
    padding: 0px !important;
    margin: 15px 0px !important;
  }

  .thumb_slider_section .thumb_slider_sectionWrapper {
    padding: 0px 25px !important;
  }

  div.speacialProgrames .thumb_slider_sectionWrapper {
    width: 100% !important;
  }

  footer .MuiGrid-root.item3 {
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    justify-content: center !important;
    flex-wrap: wrap;
  }

  div.programe-schedule-banner {
    margin-top: 0px !important;
    height: 150px !important;
  }

  .programe-schedule-banner h1 {
    font-size: 28px !important;
  }

  .programe-schedule-banner h1 br {
    display: none !important;
  }

  .radioBanner {
    padding: 0px 15px;
  }

  .prayer-req-form form {
    padding: 0px 20px !important;
  }


  .radiopPlayer.full {
    z-index: 10000 !important;
    padding: 70px 25px !important;
  }

  .radiopPlayer.full .back {
    left: 10% !important;
    top: 20% !important;
    zoom: 80%;
  }

  .radioView .skeleton .box1 {
    position: relative !important;
  }

  div.radioView .skeleton .box1 {
    width: 120px !important;
    height: 152px !important;
    top: -2% !important;
  }
  
  div.radioView .skeleton .box2 {
    position: relative !important;
  }

  .radioView .skeleton {
    padding-left: 23px !important;
    padding-right: 23px !important;
  }

  .radioView .skeleton .box2 {
    top: 16% !important;
    height: 95px !important;
  }

  div.timeZone .selectDrop .MuiFormControl-root {
    min-width: 100% !important;
  }

  .go4109123758 {
    bottom: 70px !important;
  }

  .notification {
    width: 220px !important;
  }

  .notification-heading {
    font-size: 16px !important;
  }

  .notification-content {
    padding: 10px !important;
  }

  .notification-message {
    font-size: 14px !important;
    height: 40px !important;
  }

  .notification-link {
    padding: 4px 15px !important;
    font-size: 13px !important;
  }

  .notification-image .imgWrapper {
    height: 130px !important;
  }

}


@media only screen and (min-width:320px) and (max-width:1200px) {
  .homeLoadable .banner .img {
    width: 170px !important;
  }

  .liveBanner {
    width: 90% !important;
    padding-bottom: 20px;
  }

  body.light .openSearch {
    color: #000 !important;
  }


  .MuiSkeleton-rectangular {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
  }

  .MuiSkeleton-rectangular img {
    width: 40% !important;
  }

  .MuiSkeleton-rectangular {
    background-color: rgba(255, 255, 255, 0.4) !important;
    width: 100% !important;
  }

  .homeLoadable .thumb_slider_section .item img.placeholderImg {
    width: 100% !important;
  }

  .infinite-scroll-component {
    overflow-x: hidden !important;
  }

  .slick-prev,
  .slick-next {
    visibility: hidden;
  }

  .banner {
    position: relative;
  }

  .homeLoadable .placeholder {
    height: 100% !important;
  }

  .myList .row {
    margin: auto !important;
  }




  .watchNowPagePlayerWrapper {
    padding-top: 30px !important;
    margin: 0px !important;
  }

  .watchNowPagePlayerWrapper .part1 {
    order: 2;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 20px 0px !important;
  }

  .watchNowPagePlayerWrapper .part2 {
    order: 1;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .containerWrapper.watchNowContainer {
    width: 95% !important;
    padding: 0px 15px !important;
  }

  .watchNowPagePlayerWrapper .videoplayerwrapper.embed-responsive {
    height: auto !important;
  }

  .thumb_slider_section .listingPage {
    padding-bottom: 0px !important;
    padding-left: 10px;
  }

  .container-fluid.containerWrapper {
    width: 100% !important;
    padding: 0px 25px !important;
  }

  .listingPage .MuiGrid-grid-xs-12 {
    padding: 0px !important;
  }

  .searchThumb.col-md-2 .item {
    width: 100% !important;
  }


  .copyright {
    margin-bottom: 70px;
  }


  .fixedFooterNav {
    display: flex !important;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    padding: 10px 25px;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 10000;
  }

  body.dark .fixedFooterNav {
    background: #182738 !important;
  }

  .fixedFooterNav button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.8);
  }

  body.dark .fixedFooterNav button,
  body.dark .fixedFooterNav button a {
    color: #fff !important;
    opacity: 0.7;
  }

  .fixedFooterNav button a {
    color: rgba(0, 0, 0, 0.8);
  }

  .fixedFooterNav button.act {
    color: #39a1bb !important;
  }

  .fixedFooterNav button.act * {
    color: #39a1bb !important;
  }

}

@media only screen and (min-width:992px) and (max-width:1200px) {

  .contactPage .left {
    padding-left: 0px !important;
  }

  .contactPage .left,
  .contactPage .right {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }

  .contactPage .right .card .col-sm-6 {
    flex-direction: column;
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .contactPage .right .card .col-sm-6 span,
  .contactPage .right .card .col-sm-6 .info {
    width: 100%;
  }

  .contactPage .right .card .col-sm-6 span.icon {
    width: 45px;
    margin-bottom: 15px;
  }

  .plansTable .plansTableWrapper {
    width: 95% !important;
  }

  header .item1 {
    padding-left: 20px !important;
  }

  .resNav {
    max-width: 55% !important;
    padding-left: 30px;
  }

  .thumb_slider_section {
    padding: 20px 0px !important;
  }

  .thumb_slider_section .thumb_slider_sectionWrapper {
    padding: 0px 30px !important;
  }

  header .item1.part1 {
    padding-left: 25px !important;
  }

  .loginBtnWrap.loginBtnWrapRes {
    padding-right: 25px !important;
  }

  .watchNowPagePlayerWrapper {
    padding-top: 100px !important;
    margin: 0px !important;
  }

  .footer_nav ul {
    text-align: left !important;
  }

  footer .item2 {
    max-width: 70% !important;
    flex-basis: 70% !important;
  }

  footer .MuiGrid-root.item3 {
    justify-content: flex-end !important;
    padding-right: 0px !important;
  }


}

@media only screen and (min-width:320px) and (max-width:1100px) {
  .item.active {
    border: 2px solid #EA3A15 !important;
  }

  .item:hover {
    transform: scale(1) !important;
  }

  .contactBox {
    margin-top: 0px !important;
  }

  .thumb_slider_section .title a:hover {
    color: #47b9d5 !important;
  }

  div .slick-slide{
    height: calc(40vw) !important;
  }

}


@media only screen and (min-width:767px) and (max-width:1100px) {
  .footerContainer {
    flex-direction: column !important;
  }

  footer .MuiGrid-root.item3 {
    padding: 10px 15px !important;
  }

  footer .item3 img {
    width: 100px !important;
  }

  .contactBox {
    width: 80% !important;
  }

  .contactBox h3 {
    font-size: 26px !important;
  }

  .contactBox p {
    font-size: 20px !important;
  }

  header .item1 {
    max-width: 15% !important;
    flex: 0 0 15% !important;
  }

  header .item3 {
    max-width: 85% !important;
    flex: 0 0 85% !important;
  }

}

/*--ipad css---*/
@media screen and (max-device-width: 1024px) and (orientation:landscape) {
  #root.ipad {
    height: 88vh !important;
    overflow: scroll;
    overflow-x: hidden;
  }

  #root.ipad .fixedFooterNav {
    bottom: 0% !important;
  }

  .searchThumb {
    width: 20% !important;
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}


@media screen and (max-device-width: 1024px) and (orientation:landscape) {
  header .logo {
    width: 50px !important;
  }
}

@media only screen and (min-width:1024px) and (max-width:2500px) {
  .fixedFooterNav {
    display: none !important;
  }

  .res-hide.donationBtn {
    display: flex !important;
  }
}


/*
@media screen and (max-device-width: 1024px) and (orientation:Portrait ) {
  body,html,#root.ipad{height: 56vh !important; overflow: scroll; overflow-x: hidden;}
  .fixedFooterNav{bottom: 43% !important;}
  .searchThumb{width: 20% !important; flex: 0 0 20% !important; max-width: 20% !important;}
}
*/

@media only screen and (min-width:1100px) and (max-width:1200px) {
  .resNav {
    max-width: 45% !important;
  }
}

@media only screen and (min-width:1200px) and (max-width:1300px) {
  .resNav {
    max-width: 50.333333% !important;
  }

  .resNav {
    display: flex !important;
  }
}


@media only screen and (min-width:1300px) and (max-width:1500px) {
  .resNav {
    max-width: 53.333333% !important;
  }
}

@media (min-width: 1210px) and (max-width: 1500px) {
  .thumb_slider_sectionWrapper .vertical-slider .swiper-slide.v_thumb {
    width: 170px !important;
  }
}

@media (min-width: 1510px) and (max-width: 1800px) {
  .thumb_slider_sectionWrapper .vertical-slider .swiper-slide.v_thumb {
    width: 225px !important;
  }
}

@media (min-width: 2000px) and (max-width: 2800px) {
  .thumb_slider_sectionWrapper .vertical-slider .swiper-slide.v_thumb {
    width: 333px !important;
  }
}

@media (min-width: 2800px) and (max-width: 3200px) {
  .thumb_slider_sectionWrapper .vertical-slider .swiper-slide.v_thumb {
    width: 378px !important;
  }
}

@media(max-width: 1024px) {
  .schedule-wrapper {
    width: 100% !important;
  }

  .schedule-wrapper .schedule-row .ml-auto h6 {
    white-space: pre-line;
  }
}