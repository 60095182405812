.videoplayerwrapperContainer.fullscreen .videoplayerwrapper {
    /* height: 100vh !important; */
}

.playercontainer_wrapper {
    position: relative;
    height: 100% !important;
}

.playercontainer_wrapper .overlay_ {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.settingControls_wrapper {
    position: absolute;
    bottom: 85px;
    right: 10px;
    width: 300px;
    height: auto;
    z-index: 100;
    background: #262626;
    display: none;
}

.settingControls_wrapper.show {
    display: block;
}

.settingControls_wrapper .head {
    width: 100%;
    background: #171717 !important;
}

.settingControls_wrapper .head button {
    min-width: inherit !important;
    width: 50px !important;
    height: 40px !important;
}

.settingControls_wrapper .settingControls_content {
    padding: 15px 0px;
}

.liveicon {
    width: 50px !important;
}

.playercontainer_wrapper .overlay_ {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.settingControls_wrapper {
    position: absolute;
    bottom: 85px;
    right: 10px;
    width: 300px;
    height: auto;
    z-index: 100;
    background: #262626;
    display: none;
}

.settingControls_wrapper.show {
    display: block;
}

.settingControls_wrapper .head {
    width: 100%;
    background: #171717 !important;
}

.settingControls_wrapper .head button {
    min-width: inherit !important;
    width: 50px !important;
    height: 40px !important;
}

.settingControls_wrapper .settingControls_content {
    padding: 15px 0px;
}

.settingControls_content_tabs ul {
    max-height: 160px;
    overflow: scroll;
    overflow-x: hidden;
}

.settingControls_content_tabs ul::-webkit-scrollbar {
    width: 3px !important;
}

.settingControls_content_tabs ul::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4) !important;
}

.settingControls_content_tabs ul li button {
    font-size: 13px !important;
    font-weight: 400;
    position: relative;
}

.settingControls_wrapper .head ul li button:after {
    content: '';
    width: 0%;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 02.s;
    -o-transition: all 0.2s;
    border-radius: 0px 0px 3px 3px;
}

.settingControls_wrapper .head ul li.active button:after {
    left: 0px;
    width: 100%;
}

.settingControls_content_tabs ul li span {
    display: block !important;
    text-align: left;
}

.settingControls_content_tabs ul li button {
    position: relative;
}

.settingControls_content_tabs ul li button {
    position: relative !important;
    padding-left: 10px;
    opacity: 0.8;
    padding-left: 20px !important;
}

.settingControls_content_tabs ul li button:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    top: 13px;
    left: 10px;
    display: none;
}

.settingControls_content_tabs ul li button.active span {
    opacity: 1;
}

.settingControls_content_tabs ul li button.active:before {
    display: block;
}


.settingControls_content_tabs .tab_ {
    display: none;
}

.settingControls_content_tabs .tab_ ul {
    margin-bottom: 0px;
}

.settingControls_content_tabs .tab_.show {
    display: block;
}

.MuiSlider-root {
    color: #fff !important;
}

button.pl-0 {
    padding-left: 0px !important;
}

.settingControls_content_tabs .tab_ ul li button.activ::before {
    height: 100%;
    width: 1em;
    align-items: center;
    content: "\2022";
    display: inline-flex;
    justify-content: center;
}

.playercontainer_wrapper .playercontrolls__next_prev_wrapper {
    position: relative;
    top: 35% !important;
}

.player_dur {
    color: rgba(255, 255, 255, 0.7);
    font-size: 13px !important;
}

/* .videoplayerwrapper video {
    object-fit: cover;
} */


/*--player new design code start here---*/
.font-12 {
    font-size: 13px;
}


.MuiSlider-track {
    background: #49b3d8 !important;
    border: 0px !important;
}

.playerLastButtons button {
    color: #fff !important;
    padding: 8px !important;
}

.playerLastButtons button svg.font-24 {
    font-size: 24px !important;
}

.playercontainer_wrapper.show {
    visibility: visible !important;
}


.playerNewControls {
    bottom: inherit !important;
}

.playerNewControls button svg {
    font-size: 28px !important;
    width: 27px !important;
    height: 27px !important;
}

.playerNewControls button.defaultView svg {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
}

.playerNewControls .ControlsOverlay {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.playerNewControls .title {
    font-size: 14px;
    margin: 0px;
    display: block;
    padding: 15px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.0));
    font-weight: 500;
    padding-bottom: 50px;
}

.playerNewControls .videoControls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 15px;
    padding-bottom: 5px !important;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4));
    z-index: 1000;
}

.playerNewControls .playercontrolls__next_prev_wrapper {
    top: inherit !important;
}

.playerNewControls .videoControls .playercontrolls__next_prev_wrapper .playercontrolls {
    display: flex;
    align-items: center;
}

.playerNewControls .videoControls .playercontrolls__next_prev_wrapper button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.playerNewControls .videoControls .playercontrolls__next_prev_wrapper svg {
    font-size: 30px;
    color: #fff !important;
}

.MuiSlider-track {
    background: #49b3d8 !important;
    border: 0px !important;
}

.playerLastButtons button {
    color: #fff !important;
    padding: 8px !important;
}

.playerLastButtons button svg.font-24 {
    font-size: 24px !important;
}

.playercontainer_wrapper .overlay_ {
    z-index: 999 !important;
}

.playercontainer_wrapper .range_vol {
    position: absolute;
    bottom: 47px;
    left: 8px;
    height: 100px !important;
    display: none;
    width: 5px;
}

.playercontainer_wrapper .volumeSliderWrap:hover .range_vol {
    display: block !important;
}

.playercontainer_wrapper .range_vol .MuiSlider-track {
    border: 0px !important;
}


.settingControls_wrapper {
    z-index: 10000 !important;
}

.popIcon {
    position: absolute !important;
    top: 45% !important;
    right: 10px !important;
    z-index: 1000;
}

.popIcon svg {
    color: #fff !important;
}

.MuiIconButton-root:hover {
    background: rgba(255, 255, 255, 0.2) !important;
}

.playerDropdownWrapper {
    display: flex;
}

.playerDropdownWrapper .playerDropdown {
    position: absolute;
    bottom: 40px;
    right: -20%;
    min-width: 150px;
    height: auto;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 0px;
    padding-bottom: 10px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
}

.playerDropdownWrapper .playerDropdown.show {
    opacity: 1;
    visibility: visible;
    bottom: 50px;
}

.playerDropdownWrapper .playerDropdown ul {
    max-height: 250px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0px;
}

.playerDropdownWrapper .playerDropdown ul li {
    list-style: none !important;
}

.playerDropdownWrapper .playerDropdown ul::-webkit-scrollbar {
    width: 5px !important;
    cursor: pointer;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-overflow-scrolling: touch;
}




/* Track */

.playerDropdownWrapper .playerDropdown ul::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: none !important;
}


/* Handle */
.playerDropdownWrapper .playerDropdown ul::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.playerDropdownWrapper .playerDropdown ul li.active {
    position: relative;
}

.playerDropdownWrapper .playerDropdown ul li.active:after {
    position: absolute;
    top: 7px;
    left: 15px;
    font-family: FontAwesome;
    content: "\f00c";
    color: #fff;
    font-size: 10px;
    opacity: 0.9;
}


.playerDropdownWrapper .playerDropdown h4 {
    font-size: 15px !important;
}

.playerDropdownWrapper .playerDropdown ul li button {
    text-align: left !important;
    text-transform: capitalize !important;
    justify-content: flex-start !important;
    border-radius: 0px !important;
    padding: 5px 20px !important;
    padding-left: 35px !important;
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.6) !important;
    font-weight: 300 !important;
}

.playerDropdownWrapper .playerDropdown ul li button:hover,
.playerDropdownWrapper .playerDropdown ul li.active button {
    color: rgba(255, 255, 255, 1) !important;
    background: none !important;
}

.subtitleDropdown {
    min-width: 300px !important;
}

.playerNewControls {
    background: rgba(0, 0, 0, 0.3) !important;
}

.playerNewControls .playercontainer_wrapper .playercontrolls__next_prev_wrapper {
    position: static !important;
}

.videoControls .row {
    flex-direction: row !important;
}

.videoControls .row .col {
    max-width: 50% !important;
    flex: 0 0 50% !important;
}

.playerNewControls .playPause button {
    background: rgba(0, 0, 0, 0.2) !important;
    transform: scale(1);
    padding: 7px !important;
    width: 100px !important;
    height: 100px !important;
    background: rgba(255, 255, 255, 0.1) !important;
}

.playerNewControls .playPause button svg {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

@media (min-width: 320px) and (max-width: 767px) {

    .videoplayerwrapper video {
        object-fit: contain !important;
    }

    .videoDuration,
    .playerNewControls.title {
        font-size: 12px !important;
        top: 0px !important;
    }

    .playercontrolls svg {
        font-size: 20px !important;
    }

    .playerNewControls .videoControls .playercontrolls__next_prev_wrapper {
        top: 0px !important;
    }

    .playerNewControls .videoControls .playercontrolls__next_prev_wrapper button {
        padding: 0px !important;
    }

    .playerLastButtons button {
        padding: 5px !important;
    }

    .playerLastButtons button svg,
    .popIcon svg {
        font-size: 16px !important;
    }

    .playercontainer_wrapper .range_vol {
        left: 35% !important;
        display: block !important;
        left: -7% !important;
    }


    .volumeSliderWrap:hover .range_vol {
        display: block !important;
    }

    .playerNewControls .playPause button svg {
        font-size: 25px !important;
    }


    .playerDropdownWrapper .playerDropdown {
        position: fixed;
        bottom: -100% !important;
        width: 95% !important;
        left: 2.5% !important;
        background: rgb(34 34 34);
        transform-origin: bottom center;
        -webkit-transform: rotateY(-90deg);
        -moz-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotarotateYteX(-90deg);
        transform: rotateY(-90deg);
        -webkit-transition: all .25s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        transform: scale(0.5);

    }


    .playerDropdownWrapper .playerDropdown.show {
        bottom: 80px !important;
        opacity: 1;
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        -ms-transform: rotateY(0);
        -o-transform: rotateY(0);
        transform: rotateY(0);
        visibility: visible;
        transform: scale(1);
    }

    .playercontainer_wrapper .overlay_ {
        position: fixed !important;
        z-index: 999 !important;
    }

}

.next-episode:hover {
    background: hsla(0, 0%, 100%, .2) !important;
}

.playerLastButtons button.next-episode {
    padding: 3px 10px !important;
    height: 30px !important;
}

.next-episode {
    color: #fff !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    background: hsla(0, 0%, 100%, 0) !important;
    font-size: 12px !important;
}


.playercontainer_wrapper .videoControls .control_seekbar {
    padding: 7px 0px !important;
}

.playercontainer_wrapper .videoControls .control_seekbar .MuiSlider-thumb {
    padding: 5px 5px !important;
    width: 20px;
    height: 20px;
}

.playercontainer_wrapper .videoControls .control_seekbar .MuiSlider-thumb {
    top: 50%;
    margin: 0px !important;
}


.audioPlayerImage {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}



@media only screen and (min-width:320px) and (max-width:767px) {
    .playerLastButtons button.next-episode {
        padding: 5px !important;
        height: auto !important;
        min-width: inherit !important;
        margin: 0px !important;
    }

    .playerNewControls button svg {
        font-size: 18px !important;
    }

    .popIcon {
        display: none !important;
    }

    .videoControls .col1,
    .videoControls .col2 {
        max-width: 50%;
        flex: 0 0 50%;
    }


    .playerNewControls .ControlsOverlay {
        background: none !important;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.20)) !important;
    }

}

@media only screen and (min-width:320px) and (max-width:767px) {
    .playerNewControls button svg {
        width: 22px !important;
        height: 22px !important;
    }

    .playerNewControls .playPause button {
        width: 70px !important;
        height: 70px !important;
    }

    #playercontainer_wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .videoPlayerWrapper #playercontainer_wrapper {
        width: 100% !important;
        /* height: 100vh !important; */
    }

    .videoPlayerWrapper .playerNewControls {
        position: fixed !important;
        height: 100vh !important;
    }

    /* .videoPlayerWrapper  .playerNewControls .videoControls{padding-bottom: 100px !important;} */
    .playerNewControls .videoControls {
        bottom: 5px !important;
    }
}

@media only screen and (min-width:767px) and (max-width:1080px) {
    .videoPlayerWrapper .playerNewControls {
        position: fixed !important;
        height: 100vh !important;
    }

    #playercontainer_wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .videoPlayerWrapper #playercontainer_wrapper {
        width: 100% !important;
        height: 100vh !important;
    }

    .videoPlayerWrapper .playerNewControls .videoControls {
        padding-bottom: 80px !important;
    }
}

.toogler .MuiSwitch-track {
    display: inline-block !important;
    top: -1px;
    background-color: #fff !important;
}

.toogler.Mui-checked {
    color: #fbfbfb;
}

/*--player new design code ends here---*/